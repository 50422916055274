<script>
export default {
    name: "IPLocationBlackList",
    props:{
        // 黑名单列表
        input_list:{
            type:String,
        },
        label_name:{
            type:String,
        }
    },
    data() {
        return {
            list:[],
        }
    },
    methods:{
//对数据进行增删改查的操作
        //添加一行数据
        add(){
            this.list.push("")	//添加
        },
        //排序的名次上升，指排序的下标上升
        row_up(index){
            let temp = this.list[index] ;
            this.$set(this.list,index,this.list[index-1]);
            this.$set(this.list,index-1,temp);
        },
        //排序的名次下降，指排序的下标下降
        row_down(index){
            let temp = this.list[index];
            this.$set(this.list,index,this.list[index+1]);
            this.$set(this.list,index+1,temp);
        },
        //删除一行的数据
        delete_row(index){
            this.list.splice(index,1);
        },
        //清空所有的数据
        clear(){
            this.list =[];
        },
    },
    created() {
        this.list = JSON.parse(this.input_list);
    },
    watch:{
        //对结果进行解码，上传给父组件
        list:{
            deep:true,
            handler(newValue){
                this.$emit("update:input_list",JSON.stringify(newValue));
            }
        }
    }
}
</script>

<template>
<div		class="container">
    <el-button type="primary" @click="add">添加</el-button>
    <el-button type="primary" @click="clear">清空</el-button>
    <el-table	:data="list"    width="480">
        <el-table-column  :label="label_name"  width="150">
            <template slot-scope="scope">
                <el-input	v-model="list[scope.$index]"></el-input>
            </template>
        </el-table-column>
        <el-table-column  label="排序"	width="150">
            <template slot-scope="scope">
                <el-button type="primary" @click="row_up(scope.$index)"	:disabled="scope.$index==0"	size="mini">向上</el-button>
                <el-button type="primary" @click="row_down(scope.$index)" :disabled="scope.$index==list.length-1"	size="mini">向下</el-button>
            </template>
        </el-table-column>
        <el-table-column  label="操作"  width="80">
            <template slot-scope="scope">
                <el-button type="primary" @click="delete_row(scope.$index)" size="mini">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
</div>
</template>

<style scoped lang="less">
.container{
    position: relative;
    min-width: 400px;
    max-height: 500px;
    overflow-y: auto;
}
.container	.el-table{
    border-radius: 10px;
}
</style>