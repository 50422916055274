<template>
<div class="container">
    <div class="mask"  v-if="is_show_mask">
        <div class="loading_text">正在加载中</div>
        <img  class="loading_icon" src="./image/Loading.svg"	/>
    </div>
    <div	class="content">
        <!--选择显示的群组列表-->
        <el-table   :data="group_id_list"   v-if="is_show_groups && is_choose_group">
            <el-table-column    label="分组"  width="200">
                <template v-slot="scope">
                    {{group_id_map_name[scope.row]}}
                </template>
            </el-table-column>
            <el-table-column    label="操作"  width="280">
                <template v-slot="scope">
                    <el-button
                        @click="group_id_up(scope.$index)"
                        :disabled="scope.$index==0">
                        向上
                    </el-button>
                    <el-button
                        @click="group_id_down(scope.$index)"
                        :disabled="scope.$index==group_id_list.length-1">
                        向下
                    </el-button>
                    <el-button
                        type="danger"
                        @click="group_id_list.splice(scope.$index,1)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-table   :data="goods_id_list"   v-if="!is_choose_group">
            <el-table-column    label="商品选择"  width="200">
                <template v-slot="scope">
                    {{goods_id_map_name[scope.row]}}
                </template>
            </el-table-column>
        </el-table>
        <!-- 折叠面板-根据分组折叠 -->
        <el-checkbox-group v-model="checkboxGroupValue">
        <el-collapse accordion v-model="choosedGroupIndex" @change="change_group">
            <el-collapse-item  :name="indexG" v-for="(itemG,indexG) in goodsGroupList" :key="itemG.id">
                <template slot="title">
                    <el-checkbox :label="itemG.id"  v-if="is_choose_group">
                        <div style="width: 100px;">
                            <el-tag size="small">{{itemG.goodsGroup}}</el-tag>
                        </div>
                    </el-checkbox>
                    <div    style="width: 100px;" v-else>
                        <el-tag size="small">
                            {{itemG.goodsGroup}}
                        </el-tag>
                    </div>
                    <el-button
                        type="primary"
                        size="mini"
                        style="margin-left: 150px;"
                        @click.stop.prevent="row_upGp(indexG)"
                        :disabled="indexG==0">
                        向上
                    </el-button>
                    <el-button
                        type="primary"
                        size="mini"
                        @click.stop.prevent="row_downGp(indexG)"
                        :disabled="indexG==goodsGroupList.length-1">
                        向下
                    </el-button>
                </template>
                <el-table
                    v-if="choosedGroupIndex==indexG"
                    ref="multipleTable"
                    :data="goods_list_in_group"
                    :row-key="row=>row.id">
                    <el-table-column	label=" " width="30"  v-if="!is_choose_group">
                        <template v-slot="scope" fit="contain">
                            <el-checkbox :label="scope.row.id"></el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column	label="图片" width="80">
                        <template v-slot="scope" fit="contain">
                            <el-image :src="scope.row.imgUrl"></el-image>
                        </template>
                    </el-table-column>
                    <template  v-for="(item,index) in data_colu">
                        <el-table-column :width="item.width"  :key="index" :label="item.lable" :prop="item.prop"/>
                    </template>
                </el-table>
            </el-collapse-item>
        </el-collapse>
        </el-checkbox-group>
    </div>
</div>
</template>

<script>
	import {mapState} from 'vuex'
    import {get, post} from "@/services/api";
export default{
    components:{

    },
    props: {
		input_list:{
			type:String ,
			default:null
		},
		list_max_length:{
            type:Number,
            default:5
        },
        mallIndexGoodGroupIdList:{
            type:String,
            default:null
        },
        is_choose_group:{
            type:Boolean,
            default:true
        }
    },
    data() {
        return {
			// 挑选的商品id列表
			goods_id_list:[],
            // 挑选的商品群的id列表
            group_id_list:[],
            // 是否展示遮罩层（在数据还没处理完之前展示遮罩层）
			is_show_mask:true,
			data_colu:[
				{lable:"商品id",prop:"id",width:90},
				{lable:"商品名称",prop:"goodsName",width:80},
				{lable:"商品价格",prop:"price",width:80},
				{lable:"商品实际价格",prop:"finalPrice",width:80},
				{lable:"商品组类id",prop:"goodsGroupingId",width:80}
			],
			//分组列表
			goodsGroupList:[],
            choosedGroupIndex:'',
            // 商品群的id和名称的映射
            group_id_map_name:{},
            // 商品的id和名称的映射
            goods_id_map_name:{},
            goods_list_in_group:[],
            is_show_groups:false,
        }
    },
    methods:{
		//排序的名次上升，指排序的下标上升
		row_upGp(index){
			let temp = this.goodsGroupList[index];
			this.goodsGroupList[index] = this.goodsGroupList[index-1];
			this.goodsGroupList.splice(index-1,1,temp);
		},
		//排序的名次下降，指排序的下标下降
		row_downGp(index){
			this.row_upGp(index+1);
		},

		// 处理群id的上和下
        group_id_down(index){
            let temp = this.group_id_list[index];
            this.group_id_list[index] = this.group_id_list[index + 1];
            this.group_id_list.splice(index + 1, 1, temp);
        },
        group_id_up(index){
            let temp = this.group_id_list[index];
            this.group_id_list[index] = this.group_id_list[index - 1];
            this.group_id_list.splice(index - 1, 1, temp);
        },
//下面处理初始化数据的，因为props进来的数据比较慢，需要等props内的参数进来才开始初始化的操作
		//根据输入进来的数据 处理
        async    initialize_data(){
            // 加载上商品列表
            if(this.input_list){
                this.goods_id_list = JSON.parse(this.input_list);
                await   this.init_goods_id_map_name();
            }
            // 加载商品的群组列表
            this.goodsGroupList =await get("tb-third-party-wang-mall-goods-group/listGoodsGroup");
            // 加载this.group_id_map_name，即商品的群组列表中id和name的映射 用于显示。
            this.goodsGroupList.forEach(
                group => this.group_id_map_name[group.id] = group.goodsGroup
            );
            // 加载商品群列表
            this.group_id_list = JSON.parse(this.mallIndexGoodGroupIdList);
            // 显示所选的商品群组
            this.is_show_groups = true;
		},
        async    init_goods_id_map_name(){
            if(!this.goods_id_list.length||this.goods_id_list.length == 0){
                return ;
            }
            const res_data = await post("tb-third-party-wang-mall-goods/listByIds",this.goods_id_list);
            res_data.forEach(
                goods => this.goods_id_map_name[goods.id] = goods.goodsName
            );
        },
        // 根据选择的群组，加载商品
        async    change_group(index){
            if(!index){
                index = 0;
            }
            const goods_groups_id = this.goodsGroupList[index].id;
            const request_params = {'goodsGroupingid': goods_groups_id};
            const goods = await get("tb-third-party-wang-mall-goods/getByGoodsGroup",request_params);
            this.goods_list_in_group.splice(0, this.goods_list_in_group.length , ...goods);
            this.goods_list_in_group.forEach(
                goods => this.goods_id_map_name[goods.id] = goods.goodsName
            );
        },
		//在本地数据还没有初始化之前，把页面内容遮盖，然后轮询props是否初始化完成，再进行本地数据初始化
		wait_util_not_undefined(){
			const  intervalTimer = setInterval(async ()=>{
                if(!this.input_list && !this.mallIndexGoodGroupIdList){
                    return ;
                }
                await    this.initialize_data();
                this.is_show_mask = false;
                clearInterval(intervalTimer);
            },200)
		},
    },
    computed:{
		...mapState({
			httpUrl:state=>state.global_base_url.main
		}),
        checkboxGroupValue: {
            get() {
                return this.is_choose_group ? this.group_id_list : this.goods_id_list;
            },
            set(value) {
                if (this.is_choose_group) {
                    this.group_id_list = value;
                } else {
                    this.goods_id_list = value;
                }
            }
        }
	},
    created(){
		this.wait_util_not_undefined();
    },
	watch:{
		//对结果进行解码，上传给父组件
        goods_id_list:{
			deep:true,
			handler(newValue){
				this.$emit("update:input_list",JSON.stringify(newValue));
			}
		},
        // 群组发生变化时，上传给父组件
		group_id_list(newValue){
            this.$emit("update:mallIndexGoodGroupIdList",JSON.stringify(newValue));
        }
	}
}
</script>

<style  lang="less" scoped>
	.container{
		position: relative;
		min-height: 200px;
		.mask{
			position: absolute;
			z-index: 200;
			background-color: white;
			opacity: 0.8;
			width: 100%;
			height: 100%;
			
			display: flex;	justify-content: center;	align-items: center;
			
			.loading_text{
				font-size: 23px;
			}
			
			.loading_icon{
				margin-left: 6px;
				width: 30px;		height: 30px;
				animation-name: rotate;
				animation-duration: 2s;
				animation-timing-function: linear;
				animation-iteration-count: infinite;
			}
			@keyframes rotate {
				0%		{	transform: rotate(0deg);	}
				50%		{	transform: rotate(180deg);	}
				100%		{	transform: rotate(360deg);	}
			}
		}
		.content{
			position: static;
		}
	}
</style>