<!-- 
    这个是一个挑选表，用来挑选小程序的，这个小程序是从后端获取的，所以这个组件是一个异步组件
    这是一个树状的结构 从根到叶子节点的顺序是：小程序类型->批处理类别->小程序
-->
<template>
<!--  isAnyLevel?temp:appId-->
    <el-cascader
        ref="cascader"
        v-if="is_initial"
        v-model="temp"
        :props="cascader_props"
        @change="leave_node_change"
        @expand-change="non_leave_node_change"
        :class="isAnyLevel?'widder':''"
        :placeholder="placeholder"
    >

    </el-cascader>
</template>
        
<script>
import axios from 'axios';
import { mapState } from 'vuex';
import store from '@/store'
import * as utility from '@/services/wCommonFunction.js'
import {handleResponse} from "@/services/wCommonFunction.js";
export default{
    components:{
        
    },
    props: {
        appId:{
            type:String,
        },
        array_of_type_batch_id:{
            type:Array,
            default:()=>[],
        },
        // 是否可以选第一层，第二层,也就是是否能直接选 分组类、分类
        isAnyLevel:{
            type:Boolean,
            default:false,
        }
    },
    data() {
        return {
            //所有的小程序大类
            appletTypes:[],
            cascader_current:{
                appletType:null,
                batchClassId:null,
                appId:null,  
                appName:null,
            },
            //判断从后端获取数据是否成功，这里从initialize_data是否成功执行判断,用来控制本组件什么时候加载
            is_initial:false,
            //挑选表所需要的内容
            cascader_props:{
                lazy:true,
                lazyLoad:this.lazyLoad,
                checkStrictly:this.isAnyLevel
            },
            temp:[],
            placeholder:"请选择"
        }
    },
    methods:{
        //初始化数据
        async    initialize_data(){
            const res = await axios({
                url:this.global_url+"thirdPartySmallRoutineType/getAllType"
            });
            const data = utility.handleResponse(res);
            this.appletTypes = data ;
            this.is_initial = true ;
            if(this.isAnyLevel){
              this.temp = this.array_of_type_batch_id;
              this.placeholder = await this.getPlaceHolder(this.array_of_type_batch_id);  // 这里异步执行
            }
        },
        // 设置预先设定的值
        async  getPlaceHolder(array_of_type_batch_id){
            let placeholder ="";
            if(array_of_type_batch_id.length>0){
                // 求出大类appletType
                let appletType_id = array_of_type_batch_id [0];
                placeholder = this.appletTypes.find(e=>e.id==appletType_id).name;
                // 求出batch_class
                if(array_of_type_batch_id.length>=2 && array_of_type_batch_id[1] !=-1){
                    let batch_class_id = array_of_type_batch_id[1];
                    let response = await axios({
                        url:store.state.global_base_url.main + 'tb-third-party-wang-batch-class/getById',
                        params:{id:batch_class_id},
                    });
                    let batch_class = handleResponse(response);
                    placeholder += "  /  " + batch_class.name ;
                }
                // 求小程序的名字
                if(array_of_type_batch_id.length>=3 && array_of_type_batch_id[2] !='-1'){
                    let app_id = array_of_type_batch_id[2];
                    let response = await axios({
                        url:store.state.global_base_url.main + 'thirdApplet1/getSmallRoutineByAppId',
                        params:{appId:app_id},
                    })
                    let app = handleResponse(response);
                    placeholder += '  /  ' +app.name;
                }
            }
            return placeholder;
        },
        //获取整个挑选表的树状结构
        async    lazyLoad(node,resolve){
            const {level} = node ;
            if(level == 0){ //也就是选择小程序的类型，例如：华泰期货（这种）
                let nodes = this.appletTypes.map(e=>{
                    e.label = e.name ;
                    e.value = e.id  ;
                    e.leaf  = false ; //非叶子节点
                    return e ;
                })
                resolve(nodes);
            }else if(level==1){
                let nodes = await this.get_batchClass_byAppletType(node.data.id) ;
                nodes = nodes.map(e=>{
                    e.value = e.id   ;
                    e.label = e.name +'-'+e.appCount;
                    e.leaf = false   ;
                    return e;
                });
                resolve(nodes);
            }else if(level==2){
                let nodes = await this.get_smallRoutine_byBatchClass(this.cascader_current.appletType,node.data.id) ;
                nodes = nodes.map(e=>{
                    e.value = e.appId;
                    e.label = e.name;
                    e.leaf = true ;
                    return e ;
                });
                resolve(nodes);
            }
        },
        //通过群id，获取商品
        async get_batchClass_byAppletType(appletType){
            let url = this.global_url+"tb-third-party-wang-batch-class/listWithoutConfigByAppletTypeFilterNoApp";
            let params = {
              appletType:appletType
            };
            const res = await axios({
              url:url,
              params:params
            });
            const data = utility.handleResponse(res);
            return data ;
        },
        //通过batchClass获取小程序
        async    get_smallRoutine_byBatchClass(appletType,batchClassId){
            let url = this.global_url+"tb-third-party-wang-batch-class/listAppsByClass";
            let params = {
              batchClassId:batchClassId,
              appletType:appletType
            };
            const res = await axios({
              url:url,
              params:params,
            });
            const data = utility.handleResponse(res);
            return data;
        },
        //叶子节点改变，也就是用户选择了小程序
        //用户确认了数据之后，提交给父组件用户选择的数据
        leave_node_change(e){
            this.$emit("appName",this.$refs.cascader.getCheckedNodes()[0].label);
            let appId = e.length>=2?e[2]:null
            this.$emit("update:appId",appId);
            let batchClassId = e.length>=1?e[1]:null;
            this.$emit("batchClassId",batchClassId);
            this.$emit("update:batchClassId",batchClassId);
            this.$emit("appletType",e[0]);
            this.$emit("update:appletType",e[0]);
        },
        // 当用户选择小程序类型，批处理类别，就会触发这个函数
        // 当展开的时候(也就是用户点选择的时候)，获取当前的值
        non_leave_node_change(e){
            //当用户选择第二层，就是选择批处理类别
            if(e.length >= 2){
                this.cascader_current.batchClassId = e[1];
            }
            //当用户选择第一层，就是选择小程序类型
            if(e.length >=1){
                this.cascader_current.appletType  = e[0];
            }
        }
    },
    computed:{
        ...mapState({
            global_url:state=>state.global_base_url.main
        })
    },
    created(){
        this.initialize_data();
    },
}
</script>
        
<style  lang="less" scoped>
    .el-cascader{
        width: 400px;
    }
    .widder{
        width: 570px;
    }
</style>