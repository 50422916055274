//该文件是小王用的 一些常用的函数


import { Message } from 'element-ui';
import axios  from 'axios';

// import { handleResponse } from '@/services/wCommonFunction.js'

//处理请求的结果（请求用axios做请求）,调用的时候记得检查response是await后的
//response:结果体，就是返回回来的结果，类型 object 
//isResultType: 是否是Result的格式返回的,默认为true，类型boolean
//isShowToast: 是否展示提示
//返回的：就是返回服务器要返回的内容，如果报错，就返回false
function handleResponse(response,isResultType = true,isShowToast = true){
	if(response.status != 200 ){
		Message.error("请检查网络是否正常");
		return false;
	}else if(isResultType){	//返回的是Result类型的结构体的话
		if(response.data.code != 200){
			if(isShowToast){
                Message.error("服务器报错："+response.data.message);
			}
			return false;
		}else{
			return response.data.message;
		}
	}else{	//这里就是返回的不是Result类型的结构体
		return 	response.data;
	}
}

//节流
function	throttle(fn,delayMilliTime){
	let is_working = false ;
	return 	function (...args){
		if(!is_working){
			is_working = true; 
			fn(...args);		
			setTimeout(()=>{is_working = false ;},delayMilliTime);
		}
	}
}

const createDeferred=()=>{
	let resolve,reject;
	const promise = new Promise((res,rej)=>{
		resolve = res;
		reject = rej;
	});
	return {promise,resolve,reject};
}

export {	handleResponse,throttle,createDeferred	};

