<script>
export default {
    name: "image_list_choose",
    props:{
        // 用来存储图片的地方
        image_store_url:{
            type:String,
            default:'',
        },
        image_list:{
            type:String,
            default:'[]',
        }
    },
    data() {
        return {
            // 图片列表
            fileListImg: [],
            // 鼠标当前移动到的图片的下标
            current: -1,
            // 是否显示dialog
            dVisible: false,
            // dialog图片地址
            dialogImageUrl: '',
        }
    },
    methods:{
        //删除--根据 url 的不同进行匹配删除 --删除<el-upload>图片列表中的图片
        handleRemove(index) {
            this.fileListImg.splice(index, 1);
        },
        //异常
        handleError(err, file) {
            this.$message.info("上传失败!");
        },
        //上传成功
        handleSuccess(response, file, fileList) {
            if (response.code == 500) {
                this.$message.error(response.message);
                return;
            }
            this.fileListImg.push(response.message);
        },
        //预览图 ，现在没有在用了
        handlePictureCardPreview(url) {
            this.dialogImageUrl = url;
            this.dVisible = true;
        },
    },
    created() {
        this.fileListImg = JSON.parse(this.image_list);
    },
    watch:{
        fileListImg:{
            handler:function(val){
                if(val){
                    this.$emit('update:image_list', JSON.stringify(val));
                }
            },
            deep:true
        }
    }
}
</script>

<template>
<div    class="image_container">
    <!--展示已经在的图片列表-->
    <div
        v-for="(url,index) in fileListImg"
        class="el-upload-list__item"
        :key="index"
        @mouseover="current = index"
        @mouseleave="current = -1">
        <img class="el-upload-list__item-thumbnail" :src="url">
        <!--删除图片-->
        <div
            v-if="current == index"
            class="el-upload-list__item-actions"
            @click="handleRemove(index)">
            <i class="el-icon-delete"></i>
        </div>
    </div>
    <!--上传图片用的-->
    <el-upload
        :action="image_store_url"
        :on-remove="handleRemove"
        ref="pictureUpload"
        :on-error="handleError"
        :on-success="handleSuccess"
        :data="{isOverride:true}"
        name="image"
        :show-file-list="false"
        :multiple="false">
        <!--添加按钮-->
        <div slot="default"    class="plus_container">
            <i    class="el-icon-plus"></i>
        </div>
    </el-upload>
    <!--之前用的用来预览图片用的-->
    <el-dialog :visible.sync="dVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
</div>
</template>

<style scoped lang="less">
@image_size: 178px;
.image_container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .el-upload-list__item {
        width: @image_size;
        height: @image_size;
        margin-right: 10px;
        margin-bottom: 10px;
        position: relative;
        display: inline-block;
        border: 1px solid #ebeef5;
        border-radius: 4px;
        overflow: hidden;
        background-color: #f5f7fa;
        vertical-align: top;
        box-sizing: border-box;
        transition: border-color .2s cubic-bezier(.645,.045,.355,1);

        .el-upload-list__item-thumbnail {
            width: @image_size;
            height: @image_size;
            display: block;
            border-radius: 4px;
            background-size: cover;
            background-position: center;
        }
        .el-upload-list__item-actions {
            position: absolute;
            right: 0;
            top: 0;
            background-color: rgba(0,0,0,.5);
            width: @image_size;
            height: @image_size;
            transition: background-color .2s cubic-bezier(.645,.045,.355,1);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 40px;
            color: #fff;
            cursor: pointer;
        }
    }

    .plus_container{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 174px;
        width: 174px;
        border: 4px dashed #c9c9c9;
        border-radius: 10px;
        background-color:  #eee;
        i{
            font-size: 38px;
            color: #8c939d;
        }
    }
}


</style>