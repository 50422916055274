import Vue from 'vue'
import VueRouter from 'vue-router'
// 引入组件
import Login from '../components/Login.vue'
import Home from '../components/Home.vue'
import Welcome from '../components/Welcome.vue'
import UserList from '../components/home/UserList.vue'
import RoleList from '../components/home/RoleList'
import Commodity from '../components/home/Commodity'
import Company from '../components/company'
import SmallRoutine from '../components/smallRoutine/smallRoutine'
import MessageTemplate from '../components/smallRoutine/messageTemplate'
import MessageTemplateService from '../components/smallRoutine/messageTemplateService'
import MessageTemplateServiceRel from '../components/smallRoutine/messageTemplateServiceRel'
import SyncLabel from '../components/smallRoutine/syncLabel'
import YunMaConfiguration from '../components/yunMa/yunMaConfiguration'
import UserMoneyUpdateLog from '../components/userMoneyLog/updateLog'
import UserWithdrawalRecordLog from '../components/userMoneyLog/withdrawalRecordLog'
import Applet2Configuration from '../components/applet2/configuration/configuration'
import Applet2Advertisement from '../components/applet2/advertisement/advertisement'
import SmallRoutine2 from '../components/applet2/smallRoutine2/smallRoutine2'
import TaobaoCommodityInterface from '../components/taobao/commodityInterface/commodityInterface'
import SmallRoutine3 from '../components/applet3/smallRoutine3'
import MessageTemplate3 from '../components/applet3/messageTemplate'
import MessageTemplateService3Rel from '../components/applet3/messageTemplateServiceRel'
import AlipayUserAlipayPointSend from '../components/configuration/alipayUserAlipayPointSend'
import SmallRoutine3LuckDraw from '../components/applet3/smallRoutine3LuckDraw/smallRoutine3LuckDraw'
import SmallRoutine3BottomCommodityMenuOne from '../components/applet3/bottomCommodity/menuOne/list'
import SmallRoutine3BottomCommodityMenuTwo from '../components/applet3/bottomCommodity/menuTwo/list'
import SmallRoutine3BottomCommodity from '../components/applet3/bottomCommodity/commodity/list'
import FilmConfiguration from '../components/configuration/filmConfiguration'
import FilmOrderList from "../components/film/orderList"
import FilmStatistics from "../components/film/filmStatistics"
import TurntableReward from "../components/configuration/turntableReward/turntableReward"
import TemplateApplet from "../components/template/applet/applet"
import TemplateApplet1 from "../components/template/applet1/applet1"
import ShortLink from "../components/shortLink/shortLink/list"
import ShortLinkService from "../components/shortLink/shortLinkService/list"
import ThirdPartyApplications from "../components/thirdParty/applications/applications"
import ThirdPartyTemplate from "../components/thirdParty/template"
import ThirdPartySmallRoutine from "../components/thirdParty/smallRoutine"
import MerchantCoupon from "../components/aliPay/merchantCoupon"
import MarketingPictures from "../components/aliPay/marketingPictures"
import CreateSmallRoutine from "../components/createSmallRoutine"
import TikTokController from "../components/tiktok/tiktok"
import TikTokConfiguration from "../components/tiktok/tikTokConfiguration"

// 口令红包默认
import PasswordRedEnvelopeDefault from "../components/thirdParty/other/passwordRedEnvelope/defaultMes.vue"
// 新人红包默认
import RedEnvelopeForNewcomersDefault from "../components/thirdParty/other/redEnvelopeForNewcomers/defaultConfiguration.vue"
// 新人红包列表
import RedEnvelopeForNewcomersList from "../components/thirdParty/other/redEnvelopeForNewcomers/contentList"

//陆卡汇默认配置
import LucardDefault from "../components/thirdParty/other/lucard/defaultMes.vue"
//贷款的记账本默认配置
import AccountBookDefault from '../components/thirdParty/other/loan/account_book/defaultMes.vue'
//注册支付宝账号 获取奖励
import RegisterForRedpacket from '../components/thirdParty/other/registerForRedpacket/main_page.vue'
//小程序客服的内容
import CustomerService from '../components/thirdParty/other/customerService/defaultConfig.vue'
//小程序的菜单栏里面的按钮
import MenuTemplate from '../components/thirdParty/other/redpacket/menuTemplate/defaultConfig'
//小程序的菜单栏里面的按钮 选用page时，page所对应的路径
import MenuPage from '@/components/thirdParty/other/redpacket/menuPage/defaultConfig.vue'
//商城管理_商品管理
import MallGoodsList from '../components/thirdParty/other/mall/goodsList.vue'
//商城管理_订单管理
import MallOrderList from '../components/thirdParty/other/mall/orderList.vue'
//自定义第一条广告类别
import CustomAdvertiseType from '@/components/thirdParty/other/redpacket/customAdvertiseType/defaultConfig.vue'
//小程序审核用的模板
import AuditTemplate from '@/components/thirdParty/other/redpacket/auditTemplate/defaultConfig.vue'
//商家粉丝群模板id
import FanGroupTemplateId from '@/components/thirdParty/other/redpacket/fanGroupTemplateId/defaultConfig.vue'
// 生活号配置
import Livelihood from '@/components/thirdParty/other/redpacket/livelihood/index.vue'
// 关键词管理
import administrationKeyword from '@/components/thirdParty/other/administrationKeyword/index.vue'
// 消息轮播图
import MessageCarousel from '@/components/thirdParty/other/redpacket/messageCarousel/defaultConfig.vue'
// 轮播图页面配置
import SwiperPage from '@/components/thirdParty/other/redpacket/swiperPage/defaultConfig.vue'// 轮播图页面配置
// 全局配置
import GlobalOverrideConfig from '@/components/thirdParty/other/redpacket/globalOverrideConfig'
// 全局默认配置
import GlobalDefaultConfig from "@/components/thirdParty/other/redpacket/globalDefaultConfig";
// 将图片转化成webp
import PictureToWebp from '@/components/thirdParty/other/file/pictureToWebp/index.vue'
//贷款
import loans from '@/components/thirdParty/other/loans/index.vue'
// 小王自己的电影订单查看
import filmOrder from "@/components/thirdParty/util/film/filmOrder.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path:"/login",
        component: Login,
        meta: { title:'登录' }
    },
    {
        path:"/home",
        component: Home,
        redirect:"/welcome",
        children:[
            {
                path:"/welcome",
                component:Welcome,
                meta: { title:'欢迎' }
            },
            {
                path:"/company",
                component:Company,
                meta: { title:'公司列表' }
            },
            {
                path:"/user",
                component:UserList,
                meta: { title:'用户列表' }
            },
            {
                path:"/role",
                component:RoleList,
                meta: { title:'角色列表' }
            },
            {
                path:"/commodity",
                component:Commodity,
                meta: { title:'商品列表' }
            },
            {
                path:"/smallRoutine",
                component:SmallRoutine,
                meta: { title:'小程序管理' }
            },
            {
                path:"/messageTemplate",
                component:MessageTemplate,
                meta: { title:'小程序管理——消息模板' }
            },
            {
                path:"/smallRoutineServiceRel",
                component:MessageTemplateServiceRel,
                meta: { title:'小程序管理——服务管理' }
            },
            {
                path:"/smallRoutineService",
                component:MessageTemplateService,
                meta: { title:'小程序服务管理' }
            },
            {
                path:"/syncLabel",
                component:SyncLabel,
                meta: { title:'小程序标签同步管理' }
            },
            {
                path:"/yunMaConfiguration",
                component:YunMaConfiguration,
                meta: { title:'云码配置管理' }
            },
            {
                path:"/userMoneyUpdateLog",
                component:UserMoneyUpdateLog,
                meta: { title:'用户余额变更记录' }
            },
            {
                path:"/userWithdrawalRecordLog",
                component:UserWithdrawalRecordLog,
                meta: { title:'用户提现记录' }
            },
            {
                path:"/applet2Advertisement",
                component:Applet2Advertisement,
                meta: { title:'小程序2广告管理' }
            },
            {
                path:"/applet2Configuration",
                component:Applet2Configuration,
                meta: { title:'小程序2配置管理' }
            },
            {
                path:"/smallRoutine2",
                component:SmallRoutine2,
                meta: { title:'小程序2管理' }
            },
            {
                path:"/taobaoCommodityInterface",
                component:TaobaoCommodityInterface,
                meta: { title:'商品接口管理' }
            },
            {
                path:"/smallRoutine3",
                component:SmallRoutine3,
                meta: { title:'小程序3管理' }
            },
            {
                path:"/messageTemplate3",
                component:MessageTemplate3,
                meta: { title:'小程序3消息模板管理' }
            },
            {
                path:"/smallRoutine3ServiceRel",
                component:MessageTemplateService3Rel,
                meta: { title:'小程序3服务管理' }
            },
            {
                path:"/alipayUserAlipayPointSend",
                component:AlipayUserAlipayPointSend,
                meta: { title:'集分宝管理' }
            },
            {
                path:"/smallRoutine3LuckDraw",
                component:SmallRoutine3LuckDraw,
                meta: { title:'转盘奖励管理' }
            },
            {
                path:"/smallRoutine3BottomCommodityMenuOne",
                component:SmallRoutine3BottomCommodityMenuOne,
                meta: { title:'小程序3底部商品一级菜单管理' }
            },
            {
                path:"/smallRoutine3BottomCommodityMenuTwo",
                component:SmallRoutine3BottomCommodityMenuTwo,
                meta: { title:'小程序3底部商品二级菜单管理' }
            },
            {
                path:"/smallRoutine3BottomCommodity",
                component:SmallRoutine3BottomCommodity,
                meta: { title:'小程序3底部商品管理' }
            },
            {
                path:"/filmConfiguration",
                component:FilmConfiguration,
                meta: { title:'电影管理' }
            },
            {
                path:"/filmOrderList",
                component:FilmOrderList,
                meta: { title:'电影订单列表' }
            },
            {
                path:"/filmStatistics",
                component:FilmStatistics,
                meta: { title:'电影数据统计' }
            },
            {
                path:"/turntableReward",
                component:TurntableReward,
                meta: { title:'转盘奖励管理' }
            },
            {
                path:"/templateApplet",
                component:TemplateApplet,
                meta: { title:'小程序模板管理' }
            },
            {
                path:"/templateApplet1",
                component:TemplateApplet1,
                meta: { title:'小程序1模板管理' }
            },
            {
                path:"/shortLink",
                component:ShortLink,
                meta: { title:'短链管理' }
            },
            {
                path:"/shortLinkService",
                component:ShortLinkService,
                meta: { title:'短链后台路径管理' }
            },
            {
                path:"/thirdPartyApplications",
                component:ThirdPartyApplications,
                meta: { title:'第三方应用管理' }
            },
            {
                path:"/thirdPartyTemplate",
                component:ThirdPartyTemplate,
                meta: { title:'模板列表管理' }
            },
            {
                path:"/thirdPartySmallRoutine",
                component:ThirdPartySmallRoutine,
                meta: { title:'小程序列表管理' }
            },
            {
                path:"/merchantCoupon",
                component:MerchantCoupon,
                meta: { title:'支付宝商家券管理' }
            },
            {
                path:"/marketingPictures",
                component:MarketingPictures,
                meta: { title:'支付宝营销图片管理' }
            },
            {
                path:"/createSmallRoutine",
                component:CreateSmallRoutine,
                meta: { title:'商家小程序授权' }
            },
            {
                path:"/tikTokController",
                component:TikTokController,
                meta: { title:'抖音管理' }
            },
            {
                path:"/tikTokConfiguration",
                component:TikTokConfiguration,
                meta: { title:'配置管理' }
            },
            {
                path:"/redEnvelopeForNewcomersDefault",
                component:RedEnvelopeForNewcomersDefault,
                meta: { title:'新人红包默认管理' }
            },
            {
                path:"/passwordRedEnvelopeDefault",
                component:PasswordRedEnvelopeDefault,
                meta: { title:'口令红包默认管理' }
            },
            {
                path:"/redEnvelopeForNewcomersList",
                component:RedEnvelopeForNewcomersList,
                meta: { title:'新人红包列表' }
            },
            {
                path:"/lucardDefault",
                component:LucardDefault,
                meta: { title:'陆卡' }
            },
            {
                path:"/accountBookDefault",
                component:AccountBookDefault,
                meta: { title:'记账本' }
            },
            {
                path:"/registerForRedpacket",
                component:RegisterForRedpacket,
                meta: { title:'注册奖励的手机号' }
            },
            {
                path:"/customerService",
                component:CustomerService,
                meta:{title:"客服内容"}
            },
            {
                path:"/menuTemplate",
                component:MenuTemplate,
                meta:{title:"菜单模板管理"}
            },
            {
                path:"/menuPage",
                component:MenuPage,
                meta:{title:"页面管理"}
            },
            {
                path:"/mallGoodsList",
                component:MallGoodsList,
                meta:{title:"商城管理_商品管理"}
            },
            {
                path:"/mallOrderList",
                component:MallOrderList,
                meta:{title:"商城管理_订单管理"}
            },
            {
                path:"/customAdvertiseType",
                component:CustomAdvertiseType,
                meta:{title:"自定义广告的类别"}
            },
            {
                path:"/livelihood",
                component:Livelihood,
                meta:{title:"生活号配置"}
            },
            {
                path:"/administrationKeyword",
                component:administrationKeyword,
                meta:{title:"关键词管理"}
            },
            {
                path:"/auditTemplate",
                component:AuditTemplate,
                meta:{title:"小程序审核用的模板"}
            },
            {
                path:"/fanGroupTemplateId",
                component:FanGroupTemplateId,
                meta:{title:"小程序审核用的模板"}
            },
            {
                path:"/messageCarousel",
                component:MessageCarousel,
                meta:{title:"小程序审核用的模板"}
            },
            {
                path:"/swiperPage",
                component:SwiperPage,
                meta:{title:"轮播图页面"}
            },
            {
                path:"/pictureToWebp",
                component:PictureToWebp,
                meta:{title:"将图片转成webp"}
            },
            {
                path:"/loans",
                component:loans,
                meta:{title:"贷款管理"}
            },
            {
                path: '/uv',
                component: () => import('@/components/uv/pages/uv/uv.vue'),
                meta: {title: 'UV'}
            },
            {
                path: '/life',
                component: () => import('@/components/life/pages/life/life.vue'),
                meta: {title: '生活号管理'}
            },
            {
                path: '/globalOverrideConfig',
                component:GlobalOverrideConfig,
                meta: {title: '全局覆盖配置'}
            },
            {
                path: '/globalDefaultConfig',
                component:GlobalDefaultConfig,
                meta: {title: '全局默认配置'}
            },
            {
                path: '/filmOrder',
                component: filmOrder,
                meta: {title: '电影订单'}
            }
        ]
    }
]

const router = new VueRouter({
  routes
})

//挂载路由导航守卫(拦截器)
router.beforeEach((to,from,next)=>{
  const token = window.sessionStorage.getItem("token");  //取出当前token
  //to 将要访问
  //from 从哪访问
  //next 接着干 nest(url) 重定向到url上 next()继续访问 to路径
  if(to.path=="/login") return next();  //方向内容
  if(!token) return next("/login");  //token为空时访问就返回登录页
  next(); //符合要求 放行
})


export default router
