<template>
    <div class="goodsHomepage">
        <!-- 顶部搜索框，添加按钮，批量删除按钮 -->
        <div class="selCondition">
            <el-button type="primary" icon="el-icon-arrow-left" @click="getAll" style="margin-right: 10px;">返回列表
            </el-button>
            <el-button type="primary" icon="el-icon-search" @click="getGoods" style="margin-right: 10px;">搜索
            </el-button>
            <el-input v-model="id" placeholder="请输入商品id" style="width: 200px; margin-right: 10px;"></el-input>
            <el-input v-model="title" placeholder="请输入商品名称(标题)" style="width: 200px;"></el-input>
            <el-button type="primary" icon="el-icon-zoom-in" @click="add" style="margin-left: 10px;">添加商品
            </el-button>
            <el-button type="primary" style="margin-left: 10px;" @click="showGroup">分组管理</el-button>
            <!-- 商品分组筛选商品(单选) -->
            <el-select
                style="margin: 0 0 0 10px;"
                placeholder="请选择商品分组"
                v-model="groupSelected"
                @change="groupSelectedChangeHandler"
                >
                <!-- `[{goodsGroup: '全部', id: null}, ...groupData]` 在原来的分组类别的基础上添加一个全部选项 -->
                <el-option
                    v-for="item in [{goodsGroup: '全部', id: -1}, ...groupData]"
                    :key="item.id"
                    :label="item.goodsGroup"
                    :value="item.id"
                >
                </el-option>
            </el-select>
            <!-- 商品排序类别选择 -->
            <el-select
                style="margin: 0 10px;"
                placeholder="请选择商品排序类型"
                v-model="goodsOrderTypeSelected"
                @change="goodsOrderTypeSelectedChangeHandler"
            >
                <el-option
                    v-for="item in goodsOrderType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
            <el-popconfirm
                confirm-button-text='好的'
                cancel-button-text='取消'
                icon="el-icon-info"
                icon-color="red"
                @confirm="handleDeletes()"
                title="删除后数据不能恢复,确定进行批量删除吗？"
            >
                <el-button type="danger" round slot="reference" :disabled="multiple">批量删除</el-button>
            </el-popconfirm>
        </div>
        <!-- 表单 -->
        <!-- 添加 v-if 让对话框组件出现的是否重新渲染，否则富文本编辑器中的变化会覆盖刚获取的刚获取要编辑的商品信息 -->
        <el-dialog v-if="dialogVisible" title="提示" :visible.sync="dialogVisible" width="50%" @close="dialogClose">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="商品id" v-show="(isUpd)?true:false">
                    <el-input v-model="form.id" placeholder="请输入商品id" :disabled="(isUpd)?true:false"
                              @change="inputChange(form.id)"></el-input>
                </el-form-item>
                <el-form-item label="商品名称">
                    <el-input v-model="form.goodsName" placeholder="请输入商品名称"></el-input>
                </el-form-item>
                <el-form-item label="商品组类">
                    <el-select v-model="form.goodsGroupingId" placeholder="请选择分组">
                        <el-option v-for="(item,index) in groupData" :key="index" :label="item.goodsGroup"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品标题">
                    <el-input v-model="form.title" placeholder="请输入商品标题"></el-input>
                </el-form-item>
                <el-form-item label="商品原价">
                    <el-input v-model="form.price" placeholder="请输入商品原价"
                              type="number"
                              oninput="if(value<0)value=0"></el-input>
                </el-form-item>
                <el-form-item label="商品实际价格">
                    <el-input v-model="form.finalPrice" placeholder="请输入商品实际价格"
                              type="number"
                              oninput="if(value<0)value=0"></el-input>
                </el-form-item>
                <el-form-item label="商品库存">
                    <el-input v-model="form.inventory" placeholder="请输入商品库存"
                              oninput="value = value.replace(/[^\d]/g, '');if(value.length>9); value=value.slice(0,9)"
                              :min="0"></el-input>
                </el-form-item>
                <el-form-item label="商品图片" v-show="form.goodsGroupingId">
                    <el-upload
                        name="image"
                        :action="upload_image_action_path"
                        :show-file-list='false'
                        :data="{path:'redpacket/mall/goods/'+form.goodsGroupingId,isOverride:true}"
                        :on-success="res=>{form.imgUrl=res.message}"
                    >
                        <el-image class="el-upload-img" v-if="form.imgUrl" :src="form.imgUrl" fit="contain"/>
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="商品图片列表" v-if="form.goodsGroupingId">
                    <el-upload :action="upurlList+'/'+form.goodsGroupingId" list-type="picture-card"
                               :on-remove="handleRemove" ref="pictureUpload"
                               :on-error="handleError" :on-success="handleSuccess" :disabled="uploadDisabled"
                               :before-upload="beforeUpload"
                               :data="{isOverride:true}"
                               name="image" :auto-upload="true" :multiple="false" :file-list="fileListImg">
                        <i slot="default" class="el-icon-plus"></i>
                        <div slot="file" slot-scope="{file}">
                            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                            <span class="el-upload-list__item-actions">
                                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                                    <i class="el-icon-zoom-in"></i>
                                </span>
                                <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                                    <i class="el-icon-delete"></i>
                                </span>
                           </span>
                        </div>
                    </el-upload>
                    <el-dialog :visible.sync="dVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                </el-form-item>

                <el-form-item label="商品详情图片列表" v-if="form.imgUrlDetailList && typeof form.imgUrlDetailList === 'string'">
                    <image_list_choose
                        :image_list.sync = "form.imgUrlDetailList"
                        :image_store_url="upurlList+'/'+form.goodsGroupingId">
                    </image_list_choose>
                </el-form-item>
                <!-- 富文本编辑器 -->
                <el-form-item label="产品说明">
                    <RichEditor v-model="form.productDesc"></RichEditor>
                </el-form-item>
                <el-form-item label="价格说明">
                    <RichEditor v-model="form.priceDesc"></RichEditor>
                </el-form-item>
                <el-form-item label="商品点击标签">
                    <el-input v-model="form.goodsTag" placeholder="请输入商品点击标签(例如:立即购买)"></el-input>
                </el-form-item>
                <el-form-item label="商品跳转h5链接">
                    <el-input v-model="form.goodsH5Url" placeholder="请输入商品跳转h5链接"></el-input>
                </el-form-item>
                <el-form-item label="是否跳转">
                    <el-switch v-model="form.isGoodsH5UrlOpen" active-color="#13ce66"
                               style="display: flex; margin-top: 9px;"></el-switch>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" v-show="isAdd" @click="onSubmit">立即创建</el-button>
                    <el-button type="primary" v-show="isUpd" @click="onUpd">修改</el-button>
                    <el-button @click="cancellation">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 分组表单 -->
        <el-dialog title="提示" ref="formGroup" :model="formGroup" :visible.sync="showGroupFrom" width="40%">
            <el-button type="primary" style="display: inline-block;" @click="addGroup">添加分组</el-button>
            <el-input v-model="formGroup.goodsGroup" placeholder="请输入想要添加的分组名称"
                      style="display: inline-block; width: 180px; margin-left: 8px;"></el-input>
            <el-button type="primary" style="display: inline-block; margin-left: 10px;" @click="renewGroup">保存操作
            </el-button>
            <el-table :data="groupData" height="300" style="width: 100%">
                <el-table-column label="组类名称" width="180" prop="goodsGroup">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.goodsGroup" @change="handleEnter(scope.row, scope)"
                                  :disabled="scope.row.isDisable" size="small"/>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="350">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="row_up(scope.$index)" :disabled="scope.$index==0" size="mini">
                            向上
                        </el-button>
                        <el-button type="primary" @click="row_down(scope.$index)"
                                   :disabled="scope.$index==groupData.length-1" size="mini">向下
                        </el-button>
                        <el-button size="mini" type="danger" @click="groupDel(scope.$index, scope.row)">删除</el-button>
                        <el-button size="mini" type="danger" @click="groupEdit(scope.row, scope)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <!-- 商品列表 -->
        <div class="goodsAll">
            <el-table :data="goodsData" height="800" style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column fixed label="商品id" width="180" prop="id"></el-table-column>
                <el-table-column label="商品名称" width="180" prop="goodsName"></el-table-column>
                <el-table-column label="商品组类(分组)" width="180" prop="goodsGrouping"
                                 :filters="filtersList"
                                 :filter-method="filterStatus">
                </el-table-column>
                <el-table-column label="商品标题" width="220" prop="title"></el-table-column>
                <el-table-column label="原价" width="100" prop="price"></el-table-column>
                <el-table-column label="实际价格" width="100" prop="finalPrice"></el-table-column>
                <el-table-column label="商品库存" width="100" prop="inventory"></el-table-column>
                <el-table-column label="商品图片" width="150">
                    <template slot-scope="scope">
                        <image_choose :image_url.sync="scope.row.imgUrl" :image_size="80"
                                      prefix_path="redpacket/mall/goods"
                                      :is_editable="false"></image_choose>
                    </template>
                </el-table-column>
                <el-table-column label="商品图片列表" width="270">
                    <template slot-scope="scope">
                        <div    class="image_container">
                            <el-image
                                v-for="(item, index) in JSON.parse(scope.row.imgUrlList)"
                                :key="index"
                                :src="item"
                                style="width: 80px; height: 80px;"
                                :preview-src-list="[item]">
                            </el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="详情图片列表" width="270">
                    <template slot-scope="scope">
                        <div    class="image_container">
                            <el-image
                                v-for="(item, index) in JSON.parse(scope.row.imgUrlDetailList)"
                                :key="index"
                                :src="item"
                                style="width: 80px; height: 80px;"
                                :preview-src-list="[item]">
                            </el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="商品点击标签" width="180" prop="goodsTag"></el-table-column>
                <el-table-column label="商品跳转h5链接" width="180" prop="goodsH5Url"></el-table-column>
                <el-table-column label="是否跳转" width="80" prop="isGoodsH5UrlOpen">
                    <template slot-scope="scope">
                        <el-switch :value="scope.row.isGoodsH5UrlOpen" disabled active-color="#13ce66"
                                   style="display: flex; margin-top: 9px;"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="handleEdit(scope.$index, scope.row)">编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.$index, scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 分页信息 -->
        <div class="goodsPage">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="current"
                layout="total, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>


<script>
import image_choose from '../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/image_choose.vue';
import axios from 'axios';
// import {mapState} from 'vuex';
import store from '@/store/index.js';
import RichEditor from "@/components/tcw/rich-editor/RichEditor.vue";
import image_list_choose from "./goodsList/image_list_choose.vue";

export default {
    components: {
        RichEditor,
        image_choose,
        image_list_choose
    },
    data() {
        return {
            //要删除的id数组
            ids: [],
            filtersList: [{text: '未分组', value: '该商品分组已被删除，请重新分组'}],
            //是否显示分组表单
            showGroupFrom: false,
            // 商品分组对象
            formGroup: {
                id: '',
                goodsGroup: '',
                isDisable: true,
                sortId: ''
            },
            //分组列表
            groupData: [],
            // 顶部商品分组选择器中当前选中的商品分组
            groupSelected: null,
            // 商品排序类别
            goodsOrderType: [
                // {label: '默认排序', value: 'default'},
                {label: '按照商品id排序(默认)', value: 'BY_ID'},
                {label: '按照商品分组id排序', value: 'BY_GROUP_ID'},
            ],
            // 当前选择的商品排序类别
            goodsOrderTypeSelected: 'BY_ID',
            //批量删除
            // 选中数组
            multipleSelection: '',
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            //商品id（搜索框查询用的）
            id: '',
            //商品标题（搜索框查询用的）
            title: '',

            goodsData: [],
            //输出商品列表
            tableData: [],
            //分页信息
            //当前页
            current: 0,
            //总共多少条数据
            total: 0,
            //总共几页
            pages: 0,
            //每页个数
            size: 0,
            //上传的商品图片
            //预览图片地址
            dialogImageUrl: '',
            //预览图片查看
            dVisible: false,
            //没有添加图片时隐藏 预览 下载 删除 按钮
            disabled: false,
            //禁用上传
            uploadDisabled: false,
            //<el-upload>组件的图片列表
            fileListImg: [],
            //<el-upload>组件上传成功的图片列表
            fileListDetailImg:[],
            //上传成功的图片列表
            imgList: [],
            //上传成功的商品详情图片列表
            imgListDetail: [],
            //商品图片列表 存储的路径
            upurlList: store.state.global_base_url.main + "imageManager/store?path=redpacket/mall/goodsList",
            //商品详情中的图片列表 存储的路径
            upurlListDetail: store.state.global_base_url.main + "imageManager/store?path=redpacket/mall/goodsDetailList",
            //添加表单的显示
            dialogVisible: false,
            // 表单数据
            form: {
                id: '',
                goodsName: '',
                title: '',
                goodsGroupingId: '',
                price: '',
                finalPrice: '',
                inventory: '',
                imgUrl: '',//图片
                imgUrlList: [], //图片列表
                imgUrlDetailList:[], //详情图片列表
                productDesc: '', // 产品说明
                priceDesc: '', // 价格说明
            },
            isAdd: false,
            isUpd: false
        }
    },

    methods: {
        // 对话框关闭事件处理函数
        dialogClose() {
            // 对话框只要关闭，就清空 form 防止上一次的数据影响下一个
            this.form = {}
        },
        //分组的内容
        //排序的名次上升，指排序的下标上升
        row_up(index) {
            let temp = this.groupData[index];
            this.$set(this.groupData, index, this.groupData[index - 1]);
            this.$set(this.groupData, index - 1, temp);
        },
        //排序的名次下降，指排序的下标下降
        row_down(index) {
            let temp = this.groupData[index];
            this.$set(this.groupData, index, this.groupData[index + 1]);
            this.$set(this.groupData, index + 1, temp);
        },
        //保存操作
        renewGroup() {
            if (this.ids != [] || this.ids != null) {
                axios.post(store.state.global_base_url.main + "tb-third-party-wang-mall-goods-group/delById", this.ids).then((res) => {
                    this.ids = [];
                })
            }
            for (let i = 0; i < this.groupData.length; i++) {
                this.groupData[i].isDisable = true;
            }

            axios.post(store.state.global_base_url.main + "tb-third-party-wang-mall-goods-group/addBatchGoodsGroup", this.groupData).then((res) => {
                if (res.data.message == true) {
                    this.$message.success("保存成功");

                } else {
                    this.$message.error("保存失败");
                }
            }).finally(() => {
                this.filtersList = [];
                this.getInfo();
                this.getAll();
                this.filtersList.push({text: '未分组', value: '该商品分组已被删除，请重新分组'});
            })
        },
        //过滤分组
        filterStatus(value, row) {
            return row.goodsGrouping === value;
        },
        //获取分组信息
        async getInfo() {
            await axios.get(store.state.global_base_url.main + "tb-third-party-wang-mall-goods-group/listGoodsGroup").then((res) => {
                this.groupData = res.data.message;
                for (let i = 0; i < this.groupData.length; i++) {
                    this.filtersList.push({text: this.groupData[i].goodsGroup, value: this.groupData[i].goodsGroup});
                }
            })
        },
        //显示分组信息
        showGroup() {
            this.showGroupFrom = true;
        },
        //添加分组
        addGroup() {
            this.formGroup.id = '';
            if (this.formGroup.goodsGroup == null || this.formGroup.goodsGroup == "") {
                this.$message.error("添加分组，不能为空");
            } else {
                this.groupData.push(this.formGroup);
                this.formGroup = {};
            }
        },
        //删除分组
        groupDel(index, row) {
            this.$confirm("是否确认删除该分组？", "提示", {type: "info"}).then(() => {

                if (row.id != "" || row.id != null) {
                    this.ids.push(row.id);
                }
                this.groupData.splice(index, 1);
            }).catch(() => {
                this.$message.info("取消操作");
            });
        },
        handleEnter(row) {
            row.isDisable = true;
        },
        //编辑分组
        groupEdit(row) {
            row.isDisable = false;
        },

        //编辑
        handleEdit(index, row) {
            this.dialogVisible = true;
            this.isAdd = false;
            this.isUpd = true;
            if (row.imgUrlList === null || row.imgUrlList === "") {
                this.fileListImg = [];
            } else {
                var arr = JSON.parse(row.imgUrlList);
                this.fileListImg = arr.map(t => {
                    var obj = {}
                    obj.url = t
                    return obj
                });
            }
            axios.get(store.state.global_base_url.main + "tb-third-party-wang-mall-goods/listByIdOrTitle?id=" + row.id).then((res) => {
                if (res.data.message[0] != null) {
                    this.form = res.data.message[0];
                    // if (res.data.message[0].priceDesc.length <= 0) this.form.priceDesc = '<p style="font-size: 100px">123</p>'
                    // if (res.data.message[0].productDesc.length <= 0) this.form.productDesc = '<p style="font-size: 100px">123</p>'
                } else {
                    this.$message.error("数据同步失败，自动刷新");
                }
            }).finally(() => {
                this.getAll();
            })
        },
        //删除
        handleDelete(index, row) {
            this.$confirm("此操作将永久删除当前信息，是否继续？", "提示", {type: "info"}).then(() => {
                axios.delete(store.state.global_base_url.main + "tb-third-party-wang-mall-goods/delById?id=" + row.id).then((res) => {
                    if (res.data.message == true) {
                        this.$message.success("删除成功");
                        var arr = JSON.parse(row.imgUrlList);
                        for (let i = 0; i < arr.length; i++) {
                            axios.delete(store.state.global_base_url.main + "imageManager/del?webPath=" + arr[i]);
                        }
                    } else {
                        this.$message.error("删除失败");
                    }
                }).finally(() => {
                    this.getAll();
                });
            }).catch(() => {
                this.$message.info("取消操作");
            });
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.current = val;
            this.getAll();
            console.log(`当前页: ${val}`);
        },
        //判断添加的商品id是否已存在
        inputChange(id) {
            axios.get(store.state.global_base_url.main + "tb-third-party-wang-mall-goods/listByIdOrTitle?id=" + id).then((res) => {
                if (res.data.message.length != 0) {
                    this.$message.error("该商品id已存在,请重新输入");
                    this.form.id = '';
                }
            });
        },
        //立即创建
        onSubmit() {

            this.form.imgUrlList = JSON.stringify(this.imgList);
            axios.post(store.state.global_base_url.main + "tb-third-party-wang-mall-goods/addById", this.form).then((res) => {
                if (res.data.message == true) {
                    this.$message.success("添加成功");
                    this.form = {};
                    this.dialogVisible = false;
                } else {
                    this.$message.error("添加失败");

                }
            }).finally(() => {
                this.getAll();
                this.$refs.pictureUpload.clearFiles();
                this.imgList = [];
            });
        },
        //修改
        onUpd() {
            let arr = [];
            for (let i of this.fileListImg) {
                arr.push(i.url);
            }
            this.form.imgUrlList = JSON.stringify(arr);
            axios.post(store.state.global_base_url.main + "tb-third-party-wang-mall-goods/addById", this.form).then((res) => {
                if (res.data.message == true) {
                    this.$message.success("修改成功");
                    this.form = {};
                    this.dialogVisible = false;
                    this.fileListImg = [];
                } else {
                    this.$message.error("修改失败");
                }
            }).finally(() => {
                this.getAll();
            });
        },
        //表单取消按钮
        cancellation() {
            this.form = {};
            this.dialogVisible = false;

            this.imgList = [];
            this.$refs.pictureUpload.clearFiles();
            this.$message.info("取消操作");
        },
        //图片部分-----------------------------------------------------------------------
        //异常
        handleError(err, file, fileList) {
            this.$message.info("上传失败!");
        },
        //删除--根据 url 的不同进行匹配删除 --删除<el-upload>图片列表中的图片
        handleRemove(file) {
            let uploadFiles = this.$refs.pictureUpload.uploadFiles
            for (var i = 0; i < uploadFiles.length; i++) {
                if (uploadFiles[i]['url'] == file.url) {
                    uploadFiles.splice(i, 1);

                    this.imgList.splice(i, 1);
                    this.fileListImg.splice(i, 1);
                }
            }
        },
        //限制图片上传的大小和格式
        beforeUpload(file) {
            // const isJPG = file.type === 'image/jpeg';
            // const isPNG = file.type === 'image/png';
            // const isJPEG = file.type === 'image/jpeg';
            // const isGIF = file.type === 'image/gif';
            // const isWEBP = file.type === 'image/webp';
            // const isLt150Kb = file.size / 1024 < 150;
            //
            // if (!isJPG && !isPNG && !isJPEG && !isGIF && !isWEBP) {
            // 	this.$message.error('只能上传 JPG/PNG/JPEG/GIF/WEBP 格式的图片');
            // 	return false;
            //   }
            return true;
        },
        //预览图
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dVisible = true;
        },
        //上传成功
        handleSuccess(response, file, fileList) {
            this.imgList.push(response.message);
            if (response.code == 500) {
                this.$message.error(response.message);
                return;
            }
            var obj = {};
            obj.url = fileList[fileList.length - 1].response.message;
            this.fileListImg.push(obj);
        },
        //添加商品按钮
        add() {
            this.dialogVisible = true;
            this.isAdd = true;
            this.isUpd = false;
            // 清空重置对话框中的数据
            this.clearDialogData();
        },
        //选中的数据--选中的数据可以进行批量删除
        handleSelectionChange(val) {
            this.multipleSelection = val;
            this.single = this.multipleSelection.length != 1;
            this.multiple = !this.multipleSelection.length;
        },
        //批量删除
        handleDeletes() {
            let checkArr = this.multipleSelection;   // multipleSelection存储了勾选到的数据
            let params = [];
            let self = this;
            checkArr.forEach(function (item) {
                params.push(item.id);       // 添加所有需要删除数据的id到一个数组，post提交过去
            });
            axios.post(store.state.global_base_url.main + "tb-third-party-wang-mall-goods/delByIds", params)
                .then((res) => {
                    if (res.data.message == "删除成功") {
                        this.$message.success("删除成功");

                    } else {
                        this.$message.error("删除失败");
                    }
                }).finally(() => {
                this.getAll();
            });
        },
        //条件查询
        async getGoods() {
            if (this.id == '' && this.title == '') {
                this.$message.info("请输入要查询的条件")
            } else {
                let param = "?id=" + this.id;
                param += "&title=" + this.title;
                const res = await axios.get(store.state.global_base_url.main + "tb-third-party-wang-mall-goods/listByIdOrTitle" + param);
                if (res.data.message.length == 0) {
                    this.$message.info("没有该商品信息的数据！")
                } else {
                    this.tableData = res.data.message;
                    this.id = '';
                    this.title = '';
                }

            }
        },
        //获取数据 并分页
        // 添加了根据当前选中的分组调用后端接口对商品进行筛选
        // goodsOrderTypeSelected 告诉后端需要对商品进行排序
        async getAll() {
            let getGoodsUrl = store.state.global_base_url.main + "tb-third-party-wang-mall-goods/page?current="
                + this.current
            // 如果有选择商品分组
            if (this.groupSelected && this.groupSelected !== -1) {
                getGoodsUrl = getGoodsUrl + "&groupIdList=" + [this.groupSelected]
            }
            // 如果有选择商品排序类别
            if (this.goodsOrderTypeSelected && this.goodsOrderTypeSelected !== 'default') {
                getGoodsUrl = getGoodsUrl + "&orderType=" + this.goodsOrderTypeSelected
            }
            const res = await axios.get(getGoodsUrl);
            if (res.data.message.current > res.data.message.pages) {
                const ress = await axios.get(getGoodsUrl);
                this.current = ress.data.message.current;
                this.tableData = ress.data.message.records;
                this.size = ress.data.message.size;
                this.pages = ress.data.message.pages;
                this.total = ress.data.message.total;
            } else {
                this.current = res.data.message.current;
                this.tableData = res.data.message.records;
                this.size = res.data.message.size;
                this.pages = res.data.message.pages;
                this.total = res.data.message.total;
            }
            // 第二次获取当前页面的商品列表数据，比第一次获取的商品列表数据，每个商品数据项多了 goodsGrouping 字段
            // 把已经获取的商品列表全部传递回去就是为了多查询这么一个字段
            axios.post(store.state.global_base_url.main + "tb-third-party-wang-mall-goods/listGoodsVo", this.tableData).then((e) => {
                this.goodsData = e.data.message;
            });
        },
        // 顶部商品分组选择器中当前选中的商品分组发送改变的处理函数
        groupSelectedChangeHandler() {
            this.getAll()
        },
        // 顶部商品排序类别选择器中当前选中的商品排序类别发送改变的处理函数
        goodsOrderTypeSelectedChangeHandler() {
            this.getAll()
        },
        // 清除和 dialog 相关的数据
        clearDialogData() {
            this.form = {
                id: '',
                goodsName: '',
                title: '',
                goodsGroupingId: '',
                price: '',
                finalPrice: '',
                inventory: '',
                imgUrl: '',//图片
                imgUrlList: []//图片列表
            }
            this.fileListImg = []
            this.imgList = []
        },
    },
    created() {
        this.getAll();
        this.getInfo();
    },
    computed: {
        upload_image_action_path() {//上传图片的路径
            return this.$httpUrl + 'imageManager/store';
        },
    }

}

</script>
<style scoped>
.goodsHomepage {
    display: flex;
    width: 100%;
    height: 980px;
    flex-direction: column;
}

.selCondition {
    width: 96%;
    height: 50px;
    border: 4px solid #ace7ef;
    border-radius: 12px;
    margin: 5px auto;
    line-height: 50px;
}

.goodsAll {
    margin-top: 20px;
    width: 96%;
    height: 800px;
    border: 4px solid #ace7ef;
    border-radius: 12px;
    margin: 3px auto;
    .image_container{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 160px;
        overflow-y: hidden;
    }
}

.goodsPage {
    display: grid;
    place-items: center;
    width: 96%;
    height: 50px;
    border: 4px solid #ace7ef;
    border-radius: 12px;
    margin: 5px auto;
}

.el-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 178px;
    width: 178px;
}

.el-upload .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border-color: #aaa;
    border-width: 2px;
    border-style: dashed;
    border-radius: 10px;
}

.el-upload .el-upload-img {
    width: 178px;
    height: 178px;
}

/*让输入框上下箭头不显示 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}
</style>