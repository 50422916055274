<script>
import axios from "axios";
import {handleResponse} from "@/services/wCommonFunction";

export default {
    name: "recordData",
    props: {
        type:{
            type:String ,
            default:"ORIGIN",
        }
    },
    data() {
        return {
            data:[],
            current:1,
            size:10,
            total:0,
            filter_param:{
                searchValue:"",
                searchKey:"",
            },
            is_show_mask:false,
        }
    },
    methods: {
        /**
         * 初始化数据
         * @param page_event    页码，第几页，从1开始
         * @param search_key    搜索的关键字，例如appId,userId,ip,ipLocation,isp
         * @returns {Promise<void>}
         */
        async initialize_data(page_event = 1, search_key = ""){
            let url = this.$store.state.global_base_url.main + "thirdPartyW/smallRoutine/monitor/log/page";
            let params = {
                current:page_event,
                size:this.size,
                type:this.type,
            };
            if(search_key){
                this.filter_param.searchKey = search_key;
            }
            params[this.filter_param.searchKey]=this.filter_param.searchValue;
            const response =await axios.get(url,{
                params:params,
            })
            let origin_data = handleResponse(response);
            let modified_data = origin_data.records.map(item=>{
                item.createTime = this.formatDate(item.createTime);
                return item;
            });
            this.data = [];
            this.data.push(...modified_data);
            this.current = page_event;
            this.total = origin_data.total;
        },

        async batch_copy_data(type){
            if(!this.filter_param.searchKey || !this.filter_param.searchValue){
                this.$message.error("请先搜索");
                return ;
            }
            let url = this.$store.state.global_base_url.main + "thirdPartyW/smallRoutine/monitor/log/batchCopyData";
            let params = {
                type:type
            };
            if(this.filter_param.searchKey){
                params[this.filter_param.searchKey] = this.filter_param.searchValue;
            }
            this.is_show_mask = true;
            const response = await axios.get(url,{
                params:params
            });
            const data = handleResponse(response);
            if(data){
                this.$message.success("录入成功");
                setTimeout(this.initialize_data,1000);
            }else{
                this.$message.error("录入失败");
            }
            this.is_show_mask = false;
        },

        async batch_delete_data(){
            let url = this.$store.state.global_base_url.main + "thirdPartyW/smallRoutine/monitor/log/batchDeleteData";
            let params = {
                type:this.type,
            };
            if(this.filter_param.searchKey){
                params[this.filter_param.searchKey] = this.filter_param.searchValue;
            }
            this.is_show_mask = true;
            const response = await axios.get(url,{
                params:params
            });
            const data = handleResponse(response);
            if(data){
                this.$message.success("删除成功");
                setTimeout(this.initialize_data,1000);
            }else{
                this.$message.error("删除失败");
            }
            this.is_show_mask = false;
        },
        /**
         * 工具函数，可以忽略，就是将时间戳转化为日期格式
         * @param timeStamp 时间戳，13位的，也就是毫秒级别的
         * @returns {string}    返回的是日期格式，例如 2021-01-01 12:12:12
         */
        formatDate(timeStamp) {
            var date = new Date(timeStamp);
            var YY = date.getFullYear() + '-';
            var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
            var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
            return YY + MM + DD +" "+hh + mm + ss;
        }
    },
    created() {
        this.initialize_data();
    },
    watch:{
        "filter_param.searchValue":{
            deep:true,
            handler(newValue){
                this.initialize_data();
            }
        }
    }
}
</script>

<template>
<div    style="position: relative">
    <div    class="mask"    v-if="is_show_mask">
        正在处理，请稍等...
    </div>
    <div    class="header">
        <el-input
            v-model="filter_param.searchValue"
            placeholder="请填写刷选数据..."
            :clearable="true"
        />
        <el-button  @click="initialize_data(1,'appId')">小程序ID</el-button>
        <el-button  @click="initialize_data(1,'userId')">用户ID</el-button>
        <el-button  @click="initialize_data(1,'ip')">IP</el-button>
        <el-button  @click="initialize_data(1,'ipLocation')">IP地区</el-button>
        <el-button  @click="initialize_data(1,'isp')">运营商</el-button>
        <el-button  @click="initialize_data(1)">从头刷新</el-button>
    </div>
    <el-table   :data="data"    width="1000">
        <el-table-column    label="时间"  prop="createTime"   width="160"></el-table-column>
        <el-table-column    label="小程序名称"  prop="appName"   width="190"></el-table-column>
        <el-table-column    label="小程序id"  prop="appId"   width="160"></el-table-column>
        <el-table-column    label="用户id"  prop="userId"   width="240"></el-table-column>
        <el-table-column    label="IP"  prop="ip"   width="150"></el-table-column>
        <el-table-column    label="IP归属地"  prop="ipLocation"   width="220"></el-table-column>
        <el-table-column    label="运营商"  prop="isp"   width="120"></el-table-column>
        <el-table-column    label="是否被屏蔽"  prop="ipLocation"   width="140">
            <template slot-scope="scope">
                {{ scope.row.isCanceled ? "是" : "否" }}
            </template>
        </el-table-column>
    </el-table>
    <div    class="footer">
        <div    v-if="type == 'ORIGIN'">
            <el-popconfirm
                confirm-button-text='好的'
                cancel-button-text='取消'
                icon="el-icon-info"
                icon-color="red"
                :title="total+'条记录将录入黑名单！'"
                @confirm="batch_copy_data('BLACK')"
            >
                <el-button  slot="reference">录入黑名单</el-button>
            </el-popconfirm>
            <el-popconfirm
                confirm-button-text='好的'
                cancel-button-text='取消'
                icon="el-icon-info"
                icon-color="red"
                :title="total+'条记录将录入白名单！'"
                @confirm="batch_copy_data('WHITE')"
            >
                <el-button  slot="reference">录入白名单</el-button>
            </el-popconfirm>
            <el-popconfirm
                confirm-button-text='好的'
                cancel-button-text='取消'
                icon="el-icon-info"
                icon-color="red"
                :title="total+'条记录将录入白屏名单！'"
                @confirm="batch_copy_data('BLANK_SCREEN')"
            >
                <el-button  slot="reference">录入白屏名单</el-button>
            </el-popconfirm>
        </div>
        <div>
            <el-popconfirm
                confirm-button-text='好的'
                cancel-button-text='取消'
                icon="el-icon-info"
                icon-color="red"
                :title="total+'条记录将清空！'"
                @confirm="batch_delete_data"
            >
                <el-button  slot="reference">清空数据</el-button>
            </el-popconfirm>
        </div>
        <el-pagination
            @current-change="initialize_data"
            :current-page="current"
            :page-size="size"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
    </div>
</div>
</template>

<style scoped lang="less">
.mask{
    position: absolute;
    top: 0;
    left: 0;
    width: 1400px;
    height: 100%;
    background-color: rgba(255,255,255,0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #666;
    font-weight: bold;
}
.header{
    margin-top: 15px;
    height: 60px;
    width: 1400px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    .el-input{
        width: 240px;
        margin-right: 10px;
    }
}
.el-table{
    width: 1400px;
}
.footer{
    height: 60px;
    width: 1400px;
    background-color: #FFF;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-popconfirm{
        margin-right: 10px;
        padding-right: 10px;
    }
}
</style>