<template>
  <div class="div_main">
    <el-form
        :model="updateForm"
        ref="updateFormRed"
        label-width="auto"
        v-loading="showLoading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-divider content-position="left">首页自动跳转</el-divider>
      <!-- 跳转h5 -->
      <el-form-item label="最终首页跳转h5" prop="jumpToH5">
        <el-input v-model="updateForm.jumpToH5" placeholder="请输入跳转h5" clearable></el-input>
      </el-form-item>
      <!-- 是否开启 -->
      <el-form-item label="最终跳转h5" prop="jumpToH5Status">
        <el-switch v-model="updateForm.jumpToH5Status"></el-switch>
      </el-form-item>
      <!-- 是否开启 -->
      <el-form-item label="第一次跳转h5" prop="isPreJumpToH5">
        <el-switch v-model="updateForm.isPreJumpToH5"></el-switch>
      </el-form-item>
      <!-- 跳转h5 -->
      <el-form-item label="第一次跳转h5" prop="preJumpToH5">
        <pre_jump_h5_list_editor :input_list.sync="updateForm.preJumpToH5"></pre_jump_h5_list_editor>
      </el-form-item>

      <!-- ===================================================================================== 口令红包 === 开始 === -->
      <el-divider content-position="left">生活号</el-divider>
      <!-- 是否开启生活号 -->
      <el-form-item label="是否开启生活号" prop="isShowLifeId">
        <el-switch v-model="updateForm.isShowLifeId"></el-switch>
      </el-form-item>
      <!--<el-form-item label="需要提示用户关注的生活号">-->
      <!--  <el-select v-model="updateForm.lifeInfoId" placeholder="请选择">-->
      <!--    <el-option-->
      <!--      v-for="item in lifeInfoList"-->
      <!--      :key="item.id"-->
      <!--      :label="item.lifeName"-->
      <!--      :value="item.id">-->
      <!--    </el-option>-->
      <!--  </el-select>-->
      <!--</el-form-item>-->
      <el-form-item label="生活号列表" prop="lifeIdJsonArray">
        <life_id_list_editor :input_list.sync="updateForm.lifeIdJsonArray"></life_id_list_editor>
      </el-form-item>
      <!-- ===================================================================================== 口令红包 === 结束 === -->

      <el-divider content-position="left">其他功能</el-divider>
          <!-- 灯火广告 -->
          <el-form-item label="灯火广告" prop="lightAdStatus">
            <el-switch v-model="updateForm.lightAdStatus"></el-switch>
          </el-form-item>
          <!-- 轮播图 -->
          <el-form-item label="轮播图" prop="rotationMap">
            <el-switch v-model="updateForm.rotationMap" @change="rotationMapChange"></el-switch>
          </el-form-item>
          <el-form-item label="弹窗小广告" prop="coupon">
            <el-switch v-model="updateForm.coupon"></el-switch>
          </el-form-item>
            <el-form-item label="弹窗H5路径">
                <template>
                    <el-input  v-model="updateForm.popJumpH5"	placeholder="请输入跳转h5"  clearable></el-input>
                </template>
            </el-form-item>
          <el-form-item label="扫一扫弹窗内容" prop="popContent">
              <pop-content  :popContentJson.sync="updateForm.popContentJson"></pop-content>
          </el-form-item>
      <!-- 广告部分 -->
      <el-divider content-position="left">广告图片</el-divider>
          <el-form-item label="是否展示" prop="isShowAdvertiseImage">
            <el-switch v-model="updateForm.isShowAdvertiseImage"></el-switch>
          </el-form-item>
          <el-form-item label="跳转的类型" prop="advertiseImageJumpType">
            <el-select v-model="updateForm.advertiseImageJumpType" placeholder="请选择">
              <el-option label="h5" value="h5"></el-option>
              <el-option label="app" value="app"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="跳转的h5路径" prop="advertiseImageH5">
            <el-input v-model="updateForm.advertiseImageH5" placeholder="请输入跳转h5" clearable></el-input>
          </el-form-item>
          <el-form-item label="跳转的appId" prop="advertiseImageAppId">
            <el-input v-model="updateForm.advertiseImageAppId" placeholder="请输入跳转的appId" clearable></el-input>
          </el-form-item>
          <el-form-item label="图片设置">
              <image_choose
                  :image_url.sync="updateForm.advertiseImageUrl"
                  prefix_path="redpacket/advertise"
              >
              </image_choose>
          </el-form-item>
          <el-form-item label="菜单按钮" prop="smallMallMenuTemplateId">
            <small_mall_menu_picker v-if="updateForm.smallMallMenuTemplateId"
                                    :smallMallMenuTemplateId.sync="updateForm.smallMallMenuTemplateId"></small_mall_menu_picker>
          </el-form-item>

      <!-- <el-form-item label="弹窗内容">
        <el-table	:data="lucard_pop.app_current_pop"	v-if="lucard_pop.app_current_pop.length != 0">
          <el-table-column v-for="(item,index) in lucard_pop.columnName" :width="item.width" :label="item.label" :prop="item.prop"  :key="index"> -->
      <!-- 防止某一列中的数据过长，导致某些行的高度偏高，在这里如果长度超过一定长度，会自动缩减到30个字符，然后全部内容会用一个小窗口显示 -->
      <!-- <template slot-scope="scope"	>
        <el-popover	v-if="(typeof scope.row[item.prop] == 'string') && scope.row[item.prop].length >30"	width="500"	trigger="hover"	:content="scope.row[item.prop]">
          <span slot="reference">{{scope.row[item.prop].slice(0,30)+'...'}}</span>
        </el-popover>
        <span	v-else>{{scope.row[item.prop]}}</span>
      </template>
    </el-table-column>
  </el-table>
  <div	v-else>当前为空,请选择一条模板记录把</div>
  <el-button  type="primary"	style="margin-left: 100px;"  @click="addPopTemplate()">选择一条模板记录</el-button>
  <el-select v-model="lucard_pop.temp_data_id" placeholder="请选择">
    <el-option	v-for="(item,index) in lucard_pop.template_list"	:key="index" 	:label="item.title+'-'+item.jumpType" :value='item.id'></el-option>
  </el-select>
</el-form-item> -->
      <!-- ===================================================================================== 口令红包 === 开始 === -->
      <el-divider content-position="left">口令红包</el-divider>
      <el-form-item label="打开口令红包界面时是否跳转 h5" prop="isOpenPwdRedPacketPageJumpH5">
        <el-switch v-model="updateForm.isOpenPwdRedPacketPageJumpH5"></el-switch>
      </el-form-item>
      <el-form-item label="跳转的 h5 链接" prop="openPwdRedPacketPageJumpH5">
        <el-input v-model="updateForm.openPwdRedPacketPageJumpH5" placeholder="请输入打开口令红包界面时跳转的 h5 链接" clearable></el-input>
      </el-form-item>
      <el-form-item label="领取口令红包时是否跳转 h5" prop="isClickGetPwdRedPacketJumpH5">
        <el-switch v-model="updateForm.isClickGetPwdRedPacketJumpH5"></el-switch>
      </el-form-item>
      <el-form-item label="跳转的 h5 链接" prop="clickGetPwdRedPacketJumpH5">
        <el-input v-model="updateForm.clickGetPwdRedPacketJumpH5" placeholder="请输入领取口令红包时跳转的 h5 链接" clearable></el-input>
      </el-form-item>
      <el-form-item label="口令红包的规则说明" prop="pwdRedPacketRules">
        <RichEditor v-model="updateForm.pwdRedPacketRules" editor-height="250px"></RichEditor>
      </el-form-item>
      <!-- ===================================================================================== 口令红包 === 结束 === -->
      <!-- 新人红包 -->
      <el-divider content-position="left">首页展示红包</el-divider>
          <el-form-item label="口令红包" prop="showRedEnvelopeForNewcomers">
            <el-switch v-model="updateForm.isShowPasswordsRedEnvelope"></el-switch>
          </el-form-item>
          <el-form-item label="新人红包(表单)" prop="showRedEnvelopeForNewcomers">
            <el-switch v-model="updateForm.showRedEnvelopeForNewcomers"></el-switch>
          </el-form-item>
          <el-form-item label="注册领红包" prop="isShowRegisterForRedpacket">
            <el-switch v-model="updateForm.isShowRegisterForRedpacket"></el-switch>
          </el-form-item>
          <el-form-item label="注册领红包h5" prop="registerForRedpacketUrl">
            <el-input v-model="updateForm.registerForRedpacketUrl" placeholder="请输入跳转h5" clearable></el-input>
          </el-form-item>
      <!-- 	v-if="showSingleMes.appletType == 50" -->
      <el-divider content-position="left">云码</el-divider>
          <el-form-item label="是否展示云码内容" prop="isShowYunma">
            <el-switch v-model="updateForm.isShowYunma"></el-switch>
          </el-form-item>
          <el-form-item label="是否展示入会" prop="isShowYunmaMembership">
            <el-switch v-model="updateForm.isShowYunmaMembership"></el-switch>
          </el-form-item>
          <el-form-item label="是否展示聚合拉新" prop="isShowYunmaAcquisition">
            <el-switch v-model="updateForm.isShowYunmaAcquisition"></el-switch>
          </el-form-item>
      <el-divider content-position="left">淘宝客</el-divider>
          <el-form-item label="是否展示淘宝客内容" prop="isShowYunma">
            <el-switch v-model="updateForm.isShowTaobao"></el-switch>
          </el-form-item>
          <el-divider content-position="left">是否跳转卡</el-divider>
          <el-form-item label="是否跳转卡" prop="isShowTemplateContent">
            <el-switch v-model="updateForm.isShowTemplateContent" @change="isShowTemplateContentChange()"></el-switch>
          </el-form-item>
          <el-divider content-position="left">标题的修改</el-divider>
          <el-form-item label="修改小程序的标题" prop="indexTitle">
            <el-input v-model="updateForm.indexTitle" placeholder="请输入小程序的标题" clearable></el-input>
          </el-form-item>
      <el-divider content-position="left">小程序的客服</el-divider>
          <el-form-item label="小程序的客服" prop="customerServiceFaq">
            <customer_service_faq   :list_max_length="10" :input_list.sync="updateForm.customerServiceFaq"></customer_service_faq>
          </el-form-item>
      <el-divider content-position="left">底部导航栏</el-divider>
          <el-form-item label="底部导航栏" prop="customerServiceFaq">
            <navigation_bar :input_list.sync="updateForm.navigationBar"></navigation_bar>
          </el-form-item>

      <el-divider content-position="left">小商城</el-divider>
            <jump_object_parse :jump_object_json.sync="updateForm.smallMallSwiperJson"
                         content_title="顶部轮播图"></jump_object_parse>
      <el-divider content-position="left">首页</el-divider>
            <jump_object_parse :jump_object_json.sync="updateForm.homeSwiperJson"
                         content_title="顶部轮播图"></jump_object_parse>
      <el-divider content-position="left">记账本</el-divider>
      <jump_object_parse
          :jump_object_json.sync="updateForm.accountBookSwiperJson"
          prefix_path="redpacket/accountBook/swiper"
          content_title="底部轮播图"
      ></jump_object_parse>
      <el-divider content-position="left">只有轮播图的首页</el-divider>
      <jump_object_parse
          :jump_object_json.sync="updateForm.indexSwiperJson"
          prefix_path="redpacket/swiper/swiper"
          content_title="整个轮播图"
      ></jump_object_parse>
      <!-- ==================================================================================== 商城配置项 === 开始 === -->
      <el-divider content-position="left">商城</el-divider>
      <el-form-item label="商城顶部轮播图" prop="mallIndexSwiperUrl">
        <image_choose :image_url.sync="updateForm.mallIndexSwiperUrl"
                      prefix_path="redpacket/mall/indexSwiper"></image_choose>
      </el-form-item>
	  <el-form-item label="是否开启商品详情页按钮" prop="isCommodityDetails">
	    <el-switch v-model="updateForm.isCommodityDetails"></el-switch>
	  </el-form-item>
      <el-form-item label="是否展示菜单按钮列表" prop="isShowMallMenuList">
        <el-switch v-model="updateForm.isShowMallMenuList"></el-switch>
      </el-form-item>
      <el-form-item label="是否展示抵用券领取按钮" prop="isShowMallCoupon">
        <el-switch v-model="updateForm.isShowMallCoupon"></el-switch>
      </el-form-item>
      <el-form-item label="抵用券抵扣金额" prop="couponAmount">
        <el-input-number v-model="updateForm.couponAmount" :step="1" size="medium"></el-input-number>
      </el-form-item>
	  <el-form-item label="APP可用的抵用券金额" prop="fullCoupon">
	    <el-input-number v-model="updateForm.fullCoupon" :step="1" size="medium"></el-input-number>
	  </el-form-item>
      <el-form-item label="抵用券领取按钮 logo" prop="couponBtnLogo">
        <image_choose :image_url.sync="updateForm.couponBtnLogo"
                      prefix_path="mall/coupon/btn/logo"></image_choose>
      </el-form-item>
      <el-form-item label="抵用券领取按钮标题" prop="couponBtnTitle">
        <el-input v-model="updateForm.couponBtnTitle" :maxlength="50" show-word-limit placeholder="请输入抵用券领取按钮标题" clearable></el-input>
      </el-form-item>
      <el-form-item label="抵用券弹窗标题" prop="couponPopTitle">
        <el-input v-model="updateForm.couponPopTitle" :maxlength="50" show-word-limit placeholder="请输入抵用券弹窗标题" clearable></el-input>
      </el-form-item>
	  <el-form-item label="APP商城抵用券可用类" prop="couponClass">
	    <mall_goods_list_app :input_list.sync="updateForm.couponClass"></mall_goods_list_app>
	  </el-form-item>
      <el-form-item label="是否展示领取表单按钮" prop="isShowMallForm">
        <el-switch v-model="updateForm.isShowMallForm"></el-switch>
      </el-form-item>
      <el-form-item label="领取表单的标题" prop="mallFormTitle">
        <el-input v-model="updateForm.mallFormTitle" placeholder="请输入领取表单的标题" clearable></el-input>
      </el-form-item>
      <el-form-item label="是否展示口令红包" prop="isShowMallPwdRedpacket">
        <el-switch v-model="updateForm.isShowMallPwdRedpacket"></el-switch>
      </el-form-item>
      <el-form-item label="是否展示加入粉丝群" prop="isShowMallFanGroup">
        <el-switch v-model="updateForm.isShowMallFanGroup"></el-switch>
      </el-form-item>
      <el-form-item label="商品当前价格说明" prop="mallGoodsPriceDescCurrent">
        <el-input v-model="updateForm.mallGoodsPriceDescCurrent" placeholder="请输入商品当前价格说明(未被划线的商品价格前的文字)" clearable></el-input>
      </el-form-item>
      <el-form-item label="是否展示商品的原价" prop="isShowMallGoodsPriceOrigin">
        <el-switch v-model="updateForm.isShowMallGoodsPriceOrigin"></el-switch>
      </el-form-item>
      <el-form-item label="商品原始价格说明" prop="mallGoodsPriceDescOrigin">
        <el-input v-model="updateForm.mallGoodsPriceDescOrigin" :maxlength="20" show-word-limit placeholder="请输入商品原始价格说明(划线的商品价格前的文字)" clearable></el-input>
      </el-form-item>
      <el-form-item label="是否展示商品的购买量" prop="isShowMallGoodsSaleCnt">
        <el-switch v-model="updateForm.isShowMallGoodsSaleCnt"></el-switch>
      </el-form-item>
      <el-form-item label="商城首页要展示的商品列表" prop="mallIndexGoodsList">
        <!--   :input_list.sync="updateForm.mallIndexGoodsList"-->
        <mall_goods_list
            :mallIndexGoodGroupIdList.sync ="updateForm.mallIndexGoodGroupIdList">
        </mall_goods_list>
      </el-form-item>

      <el-form-item label="商城首页轮播图条轮的h5路径">
        <el-input v-model="updateForm.mallIndexSwiperH5Url" placeholder="请输入跳转h5链接"></el-input>
      </el-form-item>
      <el-form-item label="商城首页轮播图条轮的h5路径开关">
        <el-switch v-model="updateForm.mallIsSwiperH5Url" active-color="#13ce66"
                   style="display: flex; margin-top: 9px;"></el-switch>
      </el-form-item>
      <!-- ==================================================================================== 商城配置项 === 结束 === -->
      <!-- ========================================================================================= 灯火 === 开始 === -->
      <el-divider content-position="left">灯火</el-divider>
          <el-form-item label="任务（类似云码）" prop="jumpToH5">
            <el-switch v-model="updateForm.isShowXLightTasks"></el-switch>
          </el-form-item>
          <el-form-item label="浏览15秒给奖励的" prop="jumpToH5">
            <el-switch v-model="updateForm.isShowXLightRewardedAd"></el-switch>
          </el-form-item>
          <el-form-item label="全屏广告" prop="jumpToH5">
            <el-switch v-model="updateForm.isShowXLightInterstitialAd"></el-switch>
          </el-form-item>
            <el-form-item label="展示灯火任务中的浏览任务" prop="jumpToH5">
                <el-switch v-model="updateForm.isShowXLightTasksPageView"></el-switch>
            </el-form-item>
            <el-form-item label="展示灯火任务中的资产拉新任务" prop="jumpToH5">
                <el-switch v-model="updateForm.isShowXLightTasksAcquisition"></el-switch>
            </el-form-item>
            <el-form-item label="展示灯火任务中的转化营销任务" prop="jumpToH5">
                <el-switch v-model="updateForm.isShowXLightTasksRevenue"></el-switch>
            </el-form-item>
            <el-form-item label="展示灯火任务中的其他任务" prop="jumpToH5">
                <el-switch v-model="updateForm.isShowXLightTasksOther"></el-switch>
            </el-form-item>
            <el-form-item label="灯火广告的排序" prop="jumpToH5">
                <el-select v-model="updateForm.xlightTasksOrder" placeholder="请选择">
                    <el-option
                        v-for="item in xLightTasks0rdeOptionList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
        <!-- ======================================================================================= 灯火 === 结束 === -->
			<!-- 信用卡配置项 -->
        <el-divider content-position="left">信用卡</el-divider>
			<el-form-item label="是否展示顶部图片" prop="isShowBackgroundImage">
				<el-switch v-model="updateForm.isShowBackgroundImage"></el-switch>
			</el-form-item>
			<el-form-item label="顶部图片跳转的类型" prop="backgroundImageJumpType">
				<el-select v-model="updateForm.backgroundImageJumpType" placeholder="请选择">
					<el-option label="h5" value="h5"></el-option>
					<el-option label="app" value="app"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="图片跳转的h5路径" prop="backgroundImageH5">
				<el-input v-model="updateForm.backgroundImageH5" placeholder="请输入跳转h5" clearable></el-input>
			</el-form-item>
			<el-form-item label="图片跳转的appId" prop="backgroundImageAppId">
				<el-input v-model="updateForm.backgroundImageAppId" placeholder="请输入跳转的appId" clearable></el-input>
			</el-form-item>
			<el-form-item label="顶部图片设置">
				<image_choose :image_url.sync="updateForm.backgroundImageUrl" prefix_path="redpacket/lucard/topSwiper">
				</image_choose>
			</el-form-item>
			<el-form-item label="是否展示卡片列表上方的标题" prop="isShowCardTitle">
				<el-switch v-model="updateForm.isShowCardTitle"></el-switch>
			</el-form-item>
			<el-form-item label="卡片列表上方的标题的内容" prop="cardTitle">
				<el-input v-model="updateForm.cardTitle" placeholder="请输入标题的内容" clearable></el-input>
			</el-form-item>
			<el-form-item label="是否展示卡片列表" prop="isShowCardList">
				<el-switch v-model="updateForm.isShowCardList"></el-switch>
			</el-form-item>
			<el-form-item label="信用卡配置" prop="lucardListJson">
				<card_table v-model="updateForm.lucardListJson"></card_table>
			</el-form-item>
			<el-form-item label="卡片列表中按钮的名称" prop="buttonName">
				<el-input v-model="updateForm.buttonName" placeholder="请输入按钮的名称" clearable></el-input>
			</el-form-item>
        <el-divider content-position="left">电影的轮播图</el-divider>
            <jump_object_parse
                :jump_object_json.sync="updateForm.filmSwiperJson"
                prefix_path="redpacket/utils_template/film/swiper"
                content_title="整个轮播图"/>
		</el-form>
		<!-- 底部按钮 -->
		<div class="buttomButton">
			<el-button type="primary" @click="tosave">确 定</el-button>
		</div>
		<!-- 复制于原来的底部按钮，固定与页面的右下角 -->
		<div class="fixedButton">
			<el-button type="primary" @click="tosave">确 定</el-button>
		</div>
	</div>
</template>
<script>
import {
  getConfiguration,
  updateConfiguration,
} from "@/services/thirdParty/smallRoutine/applet1/configuration.js";
import axios from 'axios';
import life_id_list_editor from './smallroutineConfiguration/life_id_list_editor.vue'
import pre_jump_h5_list_editor from './smallroutineConfiguration/pre_jump_h5_list_editor.vue'
import customer_service_faq from './smallroutineConfiguration/customer_service_faq.vue'
import navigation_bar from './smallroutineConfiguration/navigation_bar.vue'
import small_mall_menu_picker from "./smallroutineConfiguration/small_mall_menu_picker.vue";
import jump_object_parse from "./smallroutineConfiguration/jump_object_parse.vue";
import mall_goods_list from "../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/mall_goods_list.vue"
import mall_goods_list_app from "../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/mall_good_list_app.vue"
import image_choose from './smallroutineConfiguration/image_choose.vue'	//图片选择器
import card_table from '@/components/thirdParty/other/lucard/component/card_table.vue';
import RichEditor from "@/components/tcw/rich-editor/RichEditor.vue";
import popContent from "@/components/thirdParty/smallRoutine/applet1/childrenPage/smallroutineConfiguration/popContent.vue";
import store from "@/store";
export default {
  props: ["showSingleMes", "showSingleId", "poiList"],
  name: "smallroutineConfiguration",
components: {
    RichEditor,
    life_id_list_editor,
    pre_jump_h5_list_editor,
    customer_service_faq,
    navigation_bar,
    small_mall_menu_picker,
    jump_object_parse,
    mall_goods_list,
	mall_goods_list_app,
    image_choose,
    card_table,
    popContent
  },
  data() {
    return {
      updateForm: {
        poiType: [],
      },
      lifeInfoChoose: {},
      showLoading: false,
      httpUrl: this.$httpUrl,
      lucard_pop: {
        template_list: [],
        app_current_pop: [],
        temp_data_id: null,
        columnName: [	//每一列的数据
          {label: "标题", prop: "title", map_type: 'none', width: 120},
          {label: "跳转类型", prop: "jumpType", map_type: 'base', map: {'h5': 'h5', 'api': 'api'}, width: 100},
          {
            label: "是否有提示",
            prop: "isShowNotification",
            map_type: 'boolean',
            map: {'false': '无', 'true': '有'},
            width: 100
          },
          {label: "h5路径", prop: "h5Path", map_type: 'none', width: 250},
          {label: "等待时间", prop: "waitingTime", map_type: 'none', width: 100},
          {label: "产品码", prop: "posterCode", map_type: 'none', width: 280},
          // {label:"图片路径",prop:"imagePath",map_type:'image' ,width:0},
        ],
      },
        // 灯火广告排序的选项列表
        xLightTasks0rdeOptionList: [
            {value: 'BY_REWARD_DESC', label: '按照任务奖励降序'},
            {value: 'BY_REWARD_ASC,', label: '按照任务奖励升序'},
            {value: 'BY_NONE', label: '不按顺序，接口怎么给就什么顺序'},
        ],
      // 生活号管理页面中的生活号列表
      lifeInfoList: []
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 获取生活号管理页面中的生活号列表
    async getLifeInfoList() {
      const res = await axios.get(store.state.global_base_url.main + 'life/list')
      this.lifeInfoList = res.data.message
    },
    init() {
      this.getMes();
      this.getLifeInfoList()
    },
    async getMes() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await getConfiguration(this.showSingleId);
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.updateForm = res.data;
	  // console.log(JSON.stringify(this.updateForm) +'------书记')
	  // console.log(JSON.stringify(this.updateForm.couponClass) +'FFFFF')
      //获取lucard的弹窗内容
      //1.获取所有模板弹窗
      let res_lucard_pop = await axios.get(this.httpUrl + "tb-third-party-wang-lucard-card/listAllPop");
      this.lucard_pop.template_list = res_lucard_pop.data;
      //2.获取改app的弹窗
      this.lucard_pop.app_current_pop = (await axios.get(this.httpUrl + "tb-third-party-wang-lucard-card/listAppAllCardPop?appId=" + this.showSingleMes.appId)).data.message;
    },
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        let updateForm = this.updateForm;
        if (updateForm.jumpToH5Status && this.isnull(updateForm.jumpToH5)) {
          return this.$message.error(
              "首页自动跳转开关开启时跳转的h5路径不能为空"
          );
        }
        this.save();
      });
    },
    async save() {
      let res = await updateConfiguration(this.updateForm);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$message.success("操作成功");
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
    isShowTemplateContentChange() {
      if (this.updateForm.isShowTemplateContent) {
        this.updateForm.rotationMap = false;
      }
    },
    rotationMapChange() {
      if (this.updateForm.rotationMap) {
        this.updateForm.isShowTemplateContent = false;
      }
    },
    uploadSuccessHandle(res) {
      if (res.code == 200) {
        this.updateForm.popImage = res.message;
      } else {
        this.$message.error("上传图片失败");
      }

    },


    async addPopTemplate() {
      if (this.lucard_pop.temp_data_id == null) {
        return this.$message.error("请先选择模板内容")
      }
      const result = await axios({
        url: this.$httpUrl + 'tb-third-party-wang-lucard-card/addCardForAppPop',
        method: 'POST',
        data: {id: this.lucard_pop.temp_data_id, appId: this.showSingleMes.appId}
      })
      if (result.data.code != 200) {
        return this.$message.error("添加失败")
      }
      this.$message.success("添加成功")
      this.init();
      // console.log(result)
    },

    //cdn_image_choose组件上传图片的路径,并且更改图片
    async cdn_image_submit(path) {
      this.updateForm.advertiseImageUrl = path;
      const result = await axios({
        url: this.$httpUrl + 'thirdPartySmallRoutineConfiguration/uploadAdvertiseImage',
        method: 'POST',
        data: {id: this.showSingleMes.id, advertiseImageH5: path}
      })
      if (result.data.code != 200) {
        return this.$message.error("图片更改失败")
      }
      this.$message.success("图片更改成功")
    }
  },

};
</script>

<style lang="less" scoped>
.div_main_img {
  margin-top: 10px;
  width: 120px;
  height: 120px;
}

// 样式穿透，修改 element ui 选择框的样式
::v-deep .el-select {
    width: 300px;
}

// 复制于原来的底部按钮，固定与页面的右下角
.fixedButton {
    position: fixed;
    right: 0;
    bottom: 0;
    transform: translate(-50%, 0);
    padding: 2em;
}
</style>
