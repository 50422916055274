<script>
import {get} from "@/services/api";

export default {
    name: "Order",
    data() {
        return {
            order_page:{
                current:1 ,
                size:10,
                total:0 ,
                records:[]
            },
            status_map:{
                PENDING_PAYMENT:"待支付",
                CANCEL:"已取消",
                PAID:"已支付",
                TICKET_ISSUING:"出票中",
                TICKET_ISSUED:"已出票",
                TICKET_ISSUING_FAILED:"出票失败",
                SETTLED:"已结算"
            },
            search_word:{
                phoneNumber:""
            }
        }
    },
    methods: {
        async    init(){
            const params = {
                current:this.order_page.current,
                size:this.order_page.size,
                phoneNumber:this.search_word.phoneNumber
            }
            const res = await get('film/pageOrders',params);
            this.order_page.total = res.total;
            this.order_page.records.splice(0,10,...res.records);
            console.log("res_page",res);
        },
        page(current){
            this.order_page.current = current;
            this.init();
        },
    },
    created() {
        this.init();
    }
}
</script>

<template>
<div   class="all_container">
    <div   class="header">
        <el-input
            v-model="search_word.phoneNumber"
            placeholder="请输入用户手机号"
            clearable
            style="width: 300px;" />
        <el-button
            type="primary"
            icon="el-icon-search"
            @click="init">
            搜索
        </el-button>
    </div>
    <el-table   :data="order_page.records">
        <el-table-column    prop="createDate" width="170"   label="创建时间">
            <template v-slot="scope">
                {{scope.row.createDate || ""}}
            </template>
        </el-table-column>
        <el-table-column    prop="id" width="180"   label="电影方ID及支付宝交易号"></el-table-column>
        <el-table-column    prop="phoneNumber" width="120"   label="手机号"></el-table-column>
        <el-table-column    prop="price" width="70"   label="价格"></el-table-column>
        <el-table-column    prop="currentStatus" width="80"   label="订单状态">
            <template v-slot="scope">
                {{status_map[scope.row.currentStatus]}}
            </template>
        </el-table-column>
        <el-table-column    prop="appId" width="160"   label="小程序ID"></el-table-column>
        <el-table-column    prop="userId" width="220"   label="用户ID"></el-table-column>
        <el-table-column     width="150"   label="利润（没付款没有）">
            <template v-slot="scope">
                {{scope.row.totalRebate?(scope.row.price - scope.row.finalPrice + scope.row.totalRebate):'暂无法结算'}}
            </template>
        </el-table-column>
        <el-table-column    prop="payDate" width="170"   label="付款时间">
            <template v-slot="scope">
                {{scope.row.payDate || "尚未付款"}}
            </template>
        </el-table-column>
        <el-table-column    prop="filmName"     width="200"   label="电影名称"></el-table-column>
        <el-table-column    prop="cinemaName"   width="200"   label="影院名称"></el-table-column>
    </el-table>
    <div   class="footer">
        <div></div>
        <el-pagination
            @current-change="page"
            layout="prev, pager, next"
            :page-size="order_page.size"
            :total="order_page.total">
        </el-pagination>
    </div>
</div>
</template>

<style scoped lang="less">
.all_container{
    display: flex;
    flex-direction: column;
    .header{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
    }
    .footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
</style>