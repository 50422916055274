import axios from "axios";
import {handleResponse} from "@/services/wCommonFunction";
import store from "@/store";
/**
 * 通用的get请求
 * @param suffix_path    请求的后缀路径，前面是否有斜杆都可以 例如：agency-product-type/list
 * @param params       请求的参数
 * @returns {Promise<AxiosResponse<any>>} 返回的是一个promise对象
 */
async   function get(suffix_path,params={}){
    if(suffix_path.startsWith("/")){
        suffix_path = suffix_path.substring(1);
    }
    const url = store.state.global_base_url.main + suffix_path ;
    const response = await axios.get(url,{params:params});
    const res_data = handleResponse(response);
    return res_data;
}

/**
 * 通用的post请求
 * @param suffix_path 请求的后缀路径，前面是否有斜杆都可以 例如：agency-product-type/list
 * @param data 请求的参数
 * @returns {Promise<*|boolean>} 返回的是一个promise对象
 */
async function post(suffix_path,data={}){
    if(suffix_path.startsWith("/")){
        suffix_path = suffix_path.substring(1);
    }
    const url = store.state.global_base_url.main + suffix_path ;
    const response = await axios.post(url,data);
    const res_data = handleResponse(response);
    return res_data;
}

/**
 * 通用的delete请求
 * @param suffixPath    请求的后缀路径，前面是否有斜杆都可以 例如：agency-product-type/list
 * @param data          请求的参数
 * @returns {Promise<*|boolean>}    返回的是一个promise对象
 * @private
 */
async  function delete_(suffix_path,data={}){
    if(suffix_path.startsWith("/")){
        suffix_path = suffix_path.substring(1);
    }
    const url = store.state.global_base_url.main + suffix_path ;
    const response = await axios.delete(url,{data:data});
    const res_data = handleResponse(response);
    return res_data;
}

export {get,post,delete_};