
<template>
  <div>
    <h3 style="color: #00a8ff">总任务数：{{appletAmount3}}</h3>
    <h3 style="color: #af7a34">已执行的数量：{{count3}}</h3>
    <h3 style="color: #5daf34">成功的数量：{{succeedCount}}</h3>
    <h3 style="color: #af3434">失败数：{{messages.length - succeedCount}}</h3>
    <el-button type="primary" plain @click="updateVersions(1)" :loading="useDeployLoadingC" class="but">
      {{ useDeployLoadingC? '加载中' : '执行选择的类别' }}</el-button>
    <el-button type="warning" plain @click="updateVersions(2)" :loading="useDeployLoadingC" class="but">
      {{ useDeployLoadingC? '加载中' : '执行选择的第三方' }}</el-button>
    <el-button class="but" type="danger" plain @click="ceaseTask()">强制终止执行</el-button>
    <el-button class="but" type="info" plain @click="dialogVisible = true">查看执行的全部结果</el-button>
    <el-dialog
        title="查看执行的全部结果"
        :visible.sync="dialogVisible"
        width="80%"
        append-to-body>
      <exhibitionMessagesTheSecond :messages="messages"></exhibitionMessagesTheSecond>
    </el-dialog>
    <el-button style="background-color: #6c6c6c; color: #ffffff" plain @click="getTask">刷新↕获取上一次执行的结果</el-button>
  </div>
</template>

<script>
import exhibitionMessagesTheSecond from "../gatherInformation/exhibitionMessagesTheSecond.vue";

let intervalId3 = null

export default {
  components: {
    exhibitionMessagesTheSecond
  },
  props: [
      /**
       * 1. 选中的类别
       * 2. 选中的第三方
       * 3. 第三方类型的 区分未备案/已备案
       * 4. 历史记录的redisKey
       */
      "checkedCities",
      "dimensionArr",
      "appletTypeIcpStatusList",
      "updateVersionsRedisKey",
      "updateVersionsLock"
  ],
  data() {
    return {
      //按钮配置
      useDeployLoadingC: false,
      //总任务数
      appletAmount3: 0,
      //已完成数
      count3: 0,
      //成功数
      succeedCount: 0,
      //异步任务的标识
      redisKey: null,

      //记录执行的结果
      messages:[],
      //弹出对话框
      dialogVisible: false,
    }
  },
  created() {

  },
  methods: {
    //获取 第三方类型的 区分未备案/已备案 =》 返回的是 icpStatus 值（NOT_REGISTER、REGISTER_SUCCESS）
    checkIcpStatus2(appletTypeIcpStatusList) {
      // 检查是否所有元素的checked属性都为true
      if (appletTypeIcpStatusList.every(item => item.checked)) {
        this.appletTypeIcpStatus = null;
        return null;
      }
      // 找到第一个checked为true的元素并返回其icpStatus值
      const found = appletTypeIcpStatusList.find(item => item.checked);
      this.appletTypeIcpStatus = found ? found.icpStatus : null;
      return found ? found.icpStatus : null;
    },

    //查询历史记录
    async getTask() {
      const { data: res } = await this.$http.get(
          "/redisTasksBufferController/getTask?taskId=" + this.updateVersionsRedisKey
      );
      // console.log("this.updateVersionsRedisKey", this.updateVersionsRedisKey)
      // console.log("查询历史记录", res)
      if (res.message != null) {
        //总任务数
        this.appletAmount3 = res.message.taskSum? res.message.taskSum : '';
        //进度
        this.count3 = res.message.auditedAccomplishCount? res.message.auditedAccomplishCount : '';
        //失败信息列表
        this.messages = res.message?.multiAuditApplySbuMsgVoList? res.message?.multiAuditApplySbuMsgVoList : [];
        //计算出成功数
        this.succeedCount = this.messages.filter(function(message) {
          return message.operateMessage === "成功";
        }).length;
      }
    },

    //执行上传版本
    async updateVersions(is) {
      this.useDeployLoadingC = true

      let batchClassIdList = this.checkedCities.map(city => city.id); //选中的类别
      let appletTypeListList = this.dimensionArr.map(city => city.id); //选中的第三方
      if (is === 1 && batchClassIdList.length < 1) {
        this.$message.error("请选择类别");
        this.useDeployLoadingC = false
        return
      }
      if (is === 2 && appletTypeListList.length < 1) {
        this.$message.error("请选择第三方");
        this.useDeployLoadingC = false
        return
      }
      let appletTypeIcpStatus = this.checkIcpStatus2(this.appletTypeIcpStatusList)

      let multiAppletTypeOrBatchClassIdVo = {
        batchClassIdList: is === 1? batchClassIdList : null,
        appletTypeList: is === 2? appletTypeListList : null,
        appletTypeIcpStatus: appletTypeIcpStatus
      }

      //版本升级
      const { data: res } = await this.$http.post(
          "/tb-third-party-wang-batch-class/multiAppletTypeOrBatchClassIdUpgrade",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.$message.warning("「上传版本」已提交，等待审核结果");
      } else {
        this.$message.error("「上传版本」提交失败：" + res.message);
        this.useDeployLoadingC = false
      }
      console.log(res)
      this.redisKey = res.message?.redisKey;
      this.appletAmount3 = res.message?.taskSum;

      //查询进度
      this.startTaskProgress3();
    },

    //进度
    async startTaskProgress3() {
      this.count3 = 0; //已完成的数量
      this.succeedCount = 0; //已成功数
      this.messages = []; //执行结果列表
      intervalId3 = setInterval(async () => {
        //查询进度
        const { data: res } = await this.$http.get(
            "/redisTasksBufferController/getTask?taskId=" + this.redisKey
        );
        console.log("升级版本，进度：", res.message)

        if (res.message != null) {
          //进度
          this.count3 = res.message.auditedAccomplishCount? res.message.auditedAccomplishCount : '';
          //执行结果
          this.messages = res.message?.multiAuditApplySbuMsgVoList? res.message?.multiAuditApplySbuMsgVoList : [];
          //计算出成功数
          this.succeedCount = this.messages.filter(function(message) {
            return message.operateMessage === "成功";
          }).length;
        }

        //所有任务进度完成
        if (this.count3 == this.appletAmount3) {
          //解除按钮等待状态
          this.useDeployLoadingC = false;
          //清除定时器
          clearInterval(intervalId3);
          //触发父组件
          this.$emit('updateGetList');
          this.$message.success("「批量版本升级」执行完成");
        }
      }, 500); // 每0.5秒查询一次
    },

    //强制终止执行
    async ceaseTask() {
      const { data: res } = await this.$http.get(
          "/redisTasksBufferController/ceaseTask?lock=" + this.updateVersionsLock
      );
      console.log("终止res", res);

      //解除按钮等待状态
      this.useDeployLoadingC = false;
      //清除定时器
      clearInterval(intervalId3);
      //触发父组件
      this.$emit('updateGetList');
      this.$message.success("强制终止执行成功");
    }
  }

}
</script>

<style scoped lang="less">
.but{
  margin-right: 10px;
}
</style>