<template>
  <div>
    <!-- 表 （数据）的显示 -->
    <div class="table" v-if=" show_content.current_content == 'table' ">
      <el-table :data="show_data">
        <el-table-column label="图片展示">
          <template slot-scope="scope"><img class="table_image" v-if="scope.row.imagePath" :src="scope.row.imagePath"/>
          </template>
        </el-table-column>
        <el-table-column v-for="(item,index) in columnName" :width="item.width" :label="item.label" :prop="item.prop"
                         :key="index"></el-table-column>
        <el-table-column align="right" fixed="right" width="150">
          <template slot-scope="scope">
            <!--										<el-button  size="mini"	v-if="!scope.row.isDefaultTemplate"  @click="handleEdit(scope.$index, scope.row)">编辑</el-button>-->
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- <el-button  type="primary"  @click="toAdd()">新增一条记录</el-button> -->
      <el-button type="primary" style="margin-left: 100px;" @click="addTemplate()">新增模板记录</el-button>
      <el-select v-model="template_data.current" placeholder="请选择">
        <el-option v-for="(item,index) in template_data.list" :key="index" :label="item.title+'-'+item.jumpType"
                   :value='item.id'></el-option>
      </el-select>
    </div>

    <!-- 添加 编辑的地方 -->
    <div class="add" v-else-if=" show_content.current_content == 'add' ||show_content.current_content == 'edit' ">
      <div class="form">
        <div class="row" v-for="(item,index) in columnName" :key="index">
          <div class="label_container"><label>{{ item.label }}</label></div>
          <div class="input_container">
            <el-input type="text" v-if="item.is_need_map==0" v-model="tempData[item.prop]"
                      placeholder="暂时为空"></el-input>
            <el-select v-if="item.is_need_map==1 " v-model="tempData[item.prop]" placeholder="请选择">
              <el-option v-for="(item1,index1) in item.map" :label="item1" :value='index1' :key="index1"></el-option>
            </el-select>
            <!-- 下面这个是应对map的key值为boolean型 -->
            <el-select v-if="item.is_need_map==10 " v-model="tempData[item.prop]" placeholder="请选择">
              <el-option :label="item.map[false]" :value='false'></el-option>
              <el-option :label="item.map[true]" :value='true'></el-option>
            </el-select>
            <el-upload v-if="item.is_need_map ==11 && show_content.current_content == 'edit'" class="avatar-uploader"
                       list-type="picture-card" :show-file-list="false"
                       :action="httpUrl + 'tb-third-party-wang-lucard-card/uploadCardPhoto'" :data="{id:tempData.id}"
                       name="file"
                       :on-success="uploadSuccessHandle">
              <img class="div_main_img" v-if="tempData[item.prop]" :src="tempData[item.prop]"/>
              <i v-else class="el-icon-plus div_main_img"></i>
            </el-upload>
          </div>
        </div>
      </div>
      <el-button type="primary" @click="add()" v-if="show_content.current_content == 'add'">确认新增</el-button>
      <el-button type="primary" @click="edit()" v-if="show_content.current_content == 'edit'">确认编辑</el-button>
      <el-button type="primary" @click="backToTable()">返回列表</el-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {Message} from 'element-ui'

export default {
  components: {},
  props: {
    // appId: {type: String},
    value: {default: ''}
  },
  data() {
    return {
      // 展示的内容
      show_content: {
        current_content: 'table'	//备选项table,edit,add,分别是表，编辑，添加
      },
      tempData: {},		//临时存放的数据，这里是比如说你要编辑，那就是存放编辑的内容
      columnName: [	//每一列的数据
        {label: "标题", prop: "title", is_need_map: 0, width: 180},
        // {label:"第一句话",prop:"desc1",is_need_map:0,width:120},
        // {label:"第二句话",prop:"desc2",is_need_map:0,width:120},
        // {label:"第三句话",prop:"desc3",is_need_map:0,width:120},
        // {label:"左下角文字",prop:"leftBottomText",is_need_map:0},
        // {label:"是否是弹窗",prop:"isPop",is_need_map:10,map:{'false':'不弹窗','true':'弹窗'} },
        {label: "是否有提示", prop: "isShowNotification", is_need_map: 10, map: {'false': '无', 'true': '有'}},
        {label: "跳转类型", prop: "jumpType", is_need_map: 1, map: {'h5': 'h5', 'api': 'api', 'webview': 'webview'}},
        {label: "产品码", prop: "posterCode", is_need_map: 0, width: 180},
        {label: "等待时间", prop: "waitingTime", is_need_map: 0},
        {label: "排序", prop: "sort", is_need_map: 0},
        {label: "图片路径", prop: "imagePath", is_need_map: 11, width: 500},
      ],
      origin_data: [],		//原来的数据，就是从网络上发过来的数据
      show_data: [],		//表中展示的数据
      template_data: {//卡的模板的数据
        current: null,
        list: []
      },
      httpUrl: this.$httpUrl,	//存储基本的url
    }
  },
  methods: {
    //初始化数据
    async initialize_data() {
      // 获取本app的 信用卡的数据
      // const result = await axios({
      //   url: this.$httpUrl + 'tb-third-party-wang-lucard-card/listAppAllCardWithoutPop?appId=' + this.appId
      // })
      // if (result.data.code != 200) {
      //   return this.$message.error("获取数据失败");
      // }
      // console.log(result)
      // result.data.message.sort((a,b)=>{return  b.sort-a.sort})
      // this.origin_data = JSON.parse(JSON.stringify(result.data.message))
      // console.log(this.value);
      this.$emit('input', JSON.stringify(this.origin_data));
      // this.origin_data = JSON.parse(this.value);
      // console.log(this.origin_data);
      this.show_data = this.origin_data.map((item) => {
        this.columnName.forEach((item1) => {	//处理每一列
          if (item1.is_need_map == 10 || item1.is_need_map == 1) {
            item[item1.prop] = item1.map[item[item1.prop]]
          }
        })
        return item;
      })
      // 获取模板的数据
      const result_template = await axios({
        url: this.$httpUrl + 'tb-third-party-wang-lucard-card/listAll'
      })
      if (result_template.status != 200) {
        this.$message.error("获取数据失败");
      }
      this.template_data.list = result_template.data;
      //发射更新的字符串到父级

    },
    //下面·这个是在表内点击编辑按钮的时候做的事情
    // async handleEdit(index,item){
    // 		this.tempData = this.origin_data[index] ;
    // 		this.show_content.current_content = 'edit'
    // },
    //删除
    async handleDelete(item) {
      // 确保 item 是对象并且有 id 属性
      if (item && item.id) {
        // 删除本地数组中的元素
        this.origin_data = this.origin_data.filter((card) => card.id !== item.id);
        // 重新初始化数据
        await this.initialize_data();
        // 显示成功消息
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败，未找到相应的项");
      }
    },

    // 增加
    async	add(){
    		const result = await axios({
    				url:this.$httpUrl + 'tb-third-party-wang-lucard-card/addCardForApp',
    				method:'POST',
    				data:{ ...this.tempData,appId:this.appId }
    		})
    		if(result.data.code != 200 ){
    			return	this.$message.error("添加失败")
    		}
    		this.$message.success("添加成功")
    		this.backToTable();
    },
    //新增模板记录
    async addTemplate() {
      // if (this.template_data.current == null) {
      //   return this.$message.error("请先选择模板内容")
      // }
      // const result = await axios({
      //   url: this.$httpUrl + 'tb-third-party-wang-lucard-card/addCardForApp',
      //   method: 'POST',
      //   data: {id: this.template_data.current, appId: this.appId}
      // })
      // if (result.data.code != 200) {
      //   return this.$message.error("添加失败")
      // }
      // this.$message.success("添加成功")
      // this.initialize_data();
      // console.log(result)
      if (this.template_data.current == null) {
        return this.$message.error("请先选择模板内容");
      }
      // 查找当前选择的模板
      const selectedTemplate = this.template_data.list.find(template => template.id === this.template_data.current);
      // 将新卡片添加到数组中
      this.origin_data.push({...selectedTemplate});
      // 重新初始化数据
      await this.initialize_data();
      this.$message.success("添加成功");
    },
    async edit() {//编辑内容
      const result = await axios({
        url: this.$httpUrl + 'tb-third-party-wang-lucard-card/update',
        method: 'put',
        data: this.tempData
      })
      if (result.status != 200) {
        return this.$message.error("编辑失败");
      }
      this.$message.success("编辑成功");
      this.backToTable();
    },
    uploadSuccessHandle(res) {
      console.log(res);
      if (res.code == 200) {
        this.tempData.imagePath = res.message;
      } else {
        this.$message.error("上传图片失败");
      }
    },

    backToTable() {
      this.show_content.current_content = 'table';
      this.tempData = {};
      this.initialize_data();
    },
    toAdd() {
      this.show_content.current_content = 'add';
      this.tempData = {};
    },
    toEdit() {

    }
  },
  watch:{
    value:{
      handler(newVal){
          if(newVal){
              this.origin_data = JSON.parse(newVal)
              //导入数据
              this.initialize_data();
          }
      },
      immediate: true,
    }
  },
  computed: {},
  created() {

  },
}
</script>

<style lang="less" scoped>
// 表内的图片
.table_image {
  width: 40px;
  height: 40px;
}


.form {
  display: flex;
  /* flex-direction: column;*/
  flex-wrap: wrap;
  justify-content: space-around;
}

.form .row {
  display: flex;
  /* background-color: aliceblue; */
  width: 350px;

  .label_container {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100px;
    height: 70px;
    color: #888888;
  }

  .input_container {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 20px;
    width: 230px;
    height: 70px;
    // 图片的
    .avatar-uploader {
      width: 50px;
      height: 50px;

      .div_main_img {
        width: 80px;
        height: 80px;
      }
    }
  }
}
</style>