<script>
import store from "@/store";
import axios from "axios";
import {handleResponse} from "@/services/wCommonFunction";

export default {
    name: "appIdWhiteList",
    components: {},
    data() {
        return {
            appIdForWhiteList: "",
            threshold:4,
            table:{
                current_data:[],
                all_data:[],
                current_page:1,
                size:10,
            }
        }
    },
    methods: {
        async    add(){
            const url = store.state.global_base_url.main + "thirdPartyW/smallRoutine/monitor/log/addWhiteList";
            const params = {
                appId: this.appIdForWhiteList,
            };
            const response = await axios.get(url, {
                params: params,
            });
            const result = handleResponse(response);
            if(result){
                this.$message.success("添加成功");
            }else {
                this.$message.info("已经在列表中了")
            }
            this.init();
        },
        async   remove(appId){
            const url = store.state.global_base_url.main +
                "thirdPartyW/smallRoutine/monitor/log/removeWhiteList/" + appId;
            const response = await axios.delete(url);
            const result = handleResponse(response);
            if(result){
                this.$message.success("删除成功");
            }else{
                this.$message.info("不在列表中");
            }
            this.init();
        },
        async   init(){
            // 阈值
            const threshold = store.state.global_base_url.main +
                "thirdPartyW/smallRoutine/monitor/log/getUserIdThresholdCount";
            const threshold_response = await axios.get(threshold);
            this.threshold = handleResponse(threshold_response);
            // 表数据
            const url = store.state.global_base_url.main + "thirdPartyW/smallRoutine/monitor/log/listAppIdWhiteList";
            const response = await axios.get(url);
            const result = handleResponse(response);
            this.table.all_data = result;
            this.page(1);
        },
        page(current){
            this.table.current_page = current;
            this.table.current_data = [];
            const startIndex = this.table.size * (this.table.current_page-1);
            this.table.current_data.push(
                ...this.table.all_data.slice(startIndex,startIndex+this.table.size)
            );
        },
        async setThreshold(){
            const url = store.state.global_base_url.main +
                "thirdPartyW/smallRoutine/monitor/log/setUserIdThresholdCount/" + this.threshold;
            const response = await axios.put(url);
            const result = handleResponse(response);
            this.$message.success("设置成功");
        },
    },
    created() {
        this.init();
    }
}
</script>

<template>
<div>
    <div    class="setThreshold">
        <el-input   v-model="threshold" placeholder="请输入阈值"></el-input>
        <el-button  type="primary"  @click="setThreshold">设置白名单小程序的最大上限</el-button>
    </div>
    <el-input   v-model="appIdForWhiteList" placeholder="请输入小程序id号"></el-input>
    <el-button  type="primary"  @click="add">添加</el-button>
    <el-table   :data="table.current_data">
        <el-table-column   label="小程序id号" >
            <template slot-scope="scope">
                <span>{{scope.row}}</span>
            </template>
        </el-table-column>
        <el-table-column   label="删除" align="right">
            <template slot-scope="scope">
                <el-button  @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-pagination
        @current-change="page"
        :current-page="table.current_page"
        :page-size="table.size"
        layout="total, prev, pager, next, jumper"
        :total="table.all_data.length">
    </el-pagination>
</div>
</template>

<style scoped lang="less">
.el-button{
    margin-left: 10px;
}
.el-input{
    width: 200px;
}
.el-table{
    max-height: 750px;
    width: 500px;
}
.setThreshold{
    padding-bottom: 10px;
    width: 100%;
}
</style>