<script>
import scan_saved_image
    from "@/components/thirdParty/smallRoutine/applet1/childrenPage/smallroutineConfiguration/scan_saved_image.vue";
import jumpCancel from "@/components/thirdParty/other/redpacket/globalOverrideConfig/jumpCancel.vue";
import axios from 'axios';
import store from '@/store/index.js';
export default {
    name: "main",
    components: {
        scan_saved_image,jumpCancel
    },
    data(){
        return {
            config:{
                scanSavedImageJson:'{}',
                jumpCancelJson:'{}'
            },
            // 是否正在加载
            is_loading:true,
        };
    },
    methods: {
        //根据输入进来的数据 处理
        async initiailize_data(){
            axios.get(store.state.global_base_url.main+ "thirdApplet1/getGlobalConfiguration")
                .then((res)=>{
                    this.config = res.data.message;
                    this.is_loading = false; // 加载完成
                });
        },
        /**
         * 保存数据
         */
        save(){
            axios.post(store.state.global_base_url.main+ "thirdApplet1/setGlobalConfiguration",this.config)
                .then((res)=>{
                    this.$message.success("成功保存");
                    this.initiailize_data();
                });
        },
    },
    created() {
        this.initiailize_data();
    },
    computed: {

    }
}
</script>

<template>
<div    class="globalOverrideConfig">
    <el-button  @click="save">所有配置保存</el-button>
    <h1>跳转屏蔽</h1>
    <el-divider content-position="left"></el-divider>
        <jumpCancel :json_value.sync="config.jumpCancelJson"    v-if="!is_loading"></jumpCancel>
    <h1>活动</h1>
    <el-divider content-position="left"></el-divider>
        <scan_saved_image   :json_value.sync="config.scanSavedImageJson"    v-if="!is_loading"></scan_saved_image>
</div>
</template>

<style scoped lang="less">
.globalOverrideConfig{
    overflow-y: scroll;
    overflow-x: auto !important;


    .button_container{
        display: flex;
        margin-bottom: 20px;
        .el-button{
            margin-right: 20px;
        }
    }
}


/* Firefox: 设置滚动条宽度和颜色 */
* {
    scrollbar-width: auto;               /* 可选：auto, thin */
    scrollbar-color: #888 #f1f1f1;         /* 第一个为滚动条颜色，第二个为轨道颜色 */
}

/* Chrome, Safari 和 Edge */

/* 整体滚动条 */
::-webkit-scrollbar {
    width: 18px;                         /* 竖直滚动条的宽度 */
    height: 18px;                        /* 水平滚动条的高度 */
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
    background: #f1f1f1;                 /* 轨道背景色 */
    border-radius: 10px;                 /* 轨道圆角 */
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    background-color: #888;              /* 滑块颜色 */
    border-radius: 10px;                 /* 滑块圆角 */
    border: 5px solid #f1f1f1;           /* 在滑块外加上轨道色边框，产生内边距效果 */
}

/* 鼠标悬停时 */
::-webkit-scrollbar-thumb:hover {
    background-color: #555;              /* 悬停时改变颜色 */
}
</style>